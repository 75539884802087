import React from 'react'
import B2BMain from './B2BMain/B2BMain'
import B2BSolution from './B2BSolution/B2BSolution'

const B2B = () => {
  return (
    <div>
        <B2BMain />
        <B2BSolution />
    </div>
  )
}

export default B2B