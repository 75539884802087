import cn from 'classnames';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import './CounterBlock.scss';

interface CounterBlockProps {
  className?: string;
  fontSize?: string;
  hasProgressBar?: boolean;
  timeDiff?: number;
  label?: string;
  tokens?: number;
  tokensCurrent?: number
}

export const CounterBlock = ({ className, fontSize = '28px', hasProgressBar, timeDiff = 0, label, tokens, tokensCurrent }: CounterBlockProps) => {
  const seconds = Math.max(Math.floor(timeDiff / 1000) % 60, 0).toString().padStart(2, '0');
  const minutes = Math.max(Math.floor(timeDiff / (1000 * 60)) % 60, 0).toString().padStart(2, '0');
  const hours = Math.max(Math.floor(timeDiff / (1000 * 60 * 60)) % 24, 0).toString().padStart(2, '0');
  const days = Math.max(Math.floor(timeDiff / (1000 * 60 * 60 * 24)), 0).toString().padStart(2, '0');

  return (
    <div className={cn('counter-block', className)}>
      <span>NEXT STAGE</span>
      <div style={{ fontSize }} className='counter-block__counter'>
        <div className={'counter-block__counter-item'}>
          <p className='counter-block__counter-item-num'>
            {days}
          </p>
          <span>DAYS</span>
        </div>
        :
        <div className={'counter-block__counter-item'}>
          <p className='counter-block__counter-item-num'>
            {hours}
          </p>
          <span>HRS</span>
        </div> :
        <div className={'counter-block__counter-item'}>
          <p className='counter-block__counter-item-num'>
            {minutes}
          </p>
          <span>MIN</span>
        </div> :
        <div className={'counter-block__counter-item'}>
          <p className='counter-block__counter-item-num'>
            {seconds}
          </p>
          <span>SEC</span>
        </div>
      </div>
      {hasProgressBar && <ProgressBar max={tokens ?? 0} current={tokensCurrent ?? 0} margin={'18px 0 0 0'} label={label} />}
    </div>
  );
};
