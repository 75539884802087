import './TitleWithIcon.scss';

interface TitleWithIconProps {
    title: string;
    icon: JSX.Element;
}

export const TitleWithIcon = ({ icon, title }: TitleWithIconProps) => {
  return (
    <h2 className={'title-h2'}>

      <div className='title-h2__icon-wrap'>
        {icon}
      </div>

      {title}

    </h2>
  );
};
