import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogotypeSvg } from '../../../icons/logotype.svg';
import { menu } from '../../../constants/menu';
import { RegisterButton } from '../RegisterButton/RegisterButton';
import { useEffect, useState } from 'react';
import { UserInfo } from '../UserInfo/UserInfo';
import { Modal } from '../Modal/Modal';
import { useAccount } from 'wagmi';
import { Connectors } from '../../Connectors/Connectors';
import { Burger } from './Burger/Burger';
import { isUserAdmin } from '../../../states/isUserAdmin';
import { useRecoilValue } from 'recoil';
import './Header.scss';

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenConnection, setIsOpenConnection] = useState(false);
  const { isConnected } = useAccount();
  const isUserAdminState = useRecoilValue(isUserAdmin);
  const menuItems = menu.map((item) => {
    return (
      <li className={'header__menu-item'} key={item.name}>
        <Link to={item.to}>{item.name}</Link>
        {item.percent && <div>%</div>}
      </li>
    );
  });

  useEffect(() => {
    if ((!isUserAdminState && location.pathname.includes('/dashboard')) ||
      (!isConnected && location.pathname.includes('/profile'))) {
      navigate('/');
    }
  }, [location.pathname, isUserAdminState, navigate, isConnected]);

  return (
    <header className='header'>
      <Modal isOpen={isOpenConnection} maxWidth='512px' onClose={() => setIsOpenConnection(false)}>
        <Connectors onClose={() => setIsOpenConnection(false)} />
      </Modal>

      <div className='container header__container'>

        <Link to={'/'}>
          <LogotypeSvg />
        </Link>

        <nav className='header__nav'>
          <ul className='header__menu-list'>
            {location.pathname !== '/' && <li className={'header__menu-item'}>
              <Link to='/'>Main Page</Link>
            </li>}
            {menuItems}
            {isUserAdminState && <li className={'header__menu-item'}>
              <Link to='/dashboard'>Dashboard</Link>
            </li>}
          </ul>
        </nav>

        <div className='header__info'>
          {isConnected || isUserAdminState ?
            <UserInfo /> : <RegisterButton showConnectors={() => setIsOpenConnection(true)} />}
        </div>

        <Burger
          menuItems={menuItems}
          isConnected={isConnected}
          isConnectorsVisible={isOpenConnection}
          showConnectors={() => setIsOpenConnection(true)}
        />

      </div>
    </header>
  );
};
