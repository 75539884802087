import React from 'react'

const B2BSolution = () => {
  return (
    <div className='b2b-solution'>
        <div className="container">
            <p className="b2b-solution__title"></p>
            <p className="b2b-solution__subtitle"></p>

            <div className="b2b-solution__grid">
                <div className="b2b-solution__item">
                    
                    <p className="b2b-solution__item-text"></p>
                </div>
                <div className="b2b-solution__item"></div>
                <div className="b2b-solution__item"></div>
            </div>
            <div className="b2b-solution__item-long">

            </div>
        </div>
    </div>
  )
}

export default B2BSolution