import { useEffect, useState } from "react";
import { ConfirmButton } from "../../../ConfirmButton/ConfirmButton";
import { toast } from "react-toastify";

export type TSmartEditor = {
  onSmartEditConfirm: (proj: {
    realTokenAddress: string;
    ccTokenAddress: string;
  }) => void;
  project: any;
  closeSmartEditor: () => void;
};

const SmartEditor = ({ onSmartEditConfirm, project, closeSmartEditor }: TSmartEditor) => {
  const [ccTokenAddress, setCCTokenAddress] = useState("");
  const [realTokenAddress, setRealTokenAddress] = useState("");
  useEffect(() => {
    setCCTokenAddress(project.ccTokenAddress);
    setRealTokenAddress(project.realTokenAddress);
  }, [project]);

  const onConfirmButtonClick = () => {
    onSmartEditConfirm({ ccTokenAddress, realTokenAddress });
    closeSmartEditor()
  };

  return (
    <div>
      <div style={{marginTop: "20px"}} className={"input-title"}>Coinsclub token contract</div>
      <input
        type="text"
        placeholder={"0x000..."}
        className={"description-editor__input"}
        name={"no"}
        value={ccTokenAddress}
        onChange={(e) => setCCTokenAddress(e.target.value)}
      />
      <div className={"input-title"}>Real token contract</div>
      <input
        type="text"
        placeholder={"0x000..."}
        className={"description-editor__input"}
        name={"no"}
        value={realTokenAddress}
        onChange={(e) => setRealTokenAddress(e.target.value)}
      />

      <ConfirmButton onClick={onConfirmButtonClick}>CONFIRM</ConfirmButton>
    </div>
  );
};

export default SmartEditor;
