import { TitleWithIcon } from '../../../components/TitleWithIcon/TitleWithIcon';
import { ReactComponent as BagIcon } from '../../../icons/bag.svg';
import { ProjectCard } from '../../../components/ProjectCard/ProjectCard';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import './Marketplace.scss';
import './filters.scss';
import { BurgerButton } from '../../../components/BurgerButton/BurgerButton';
import { BigCard } from '../../../components/BigCard/BigCard';
import Slider from 'react-slick';
import { settingsForSlidersOnMainPage } from '../../../constants/settingsForSlidersOnMainPage';
import { projectsApi } from '../../../api/projectsApi';
import { useRecoilValue } from 'recoil';
import { apiVersionState } from '../../../states/apiVersionState';
import { useLocation } from 'react-router-dom';

const filters = [
  {
    name: 'ONGOING',
    color: '#3861FB',
  },
  {
    name: 'HOT',
    color: '#EB0063',
  },
  {
    name: 'LATEST PROJECTS',
    color: '#935EDA',
  },
  {
    name: 'SOON',
    color: '#FBF8FF',
  },
  {
    name: 'MAX DISCOUNT',
    color: '#D7EB00',
  },
  {
    name: 'new',
    color: '#FD8F02',
  },
  {
    name: 'top sales',
    color: '#16C784',
  },
];

export const Marketplace = () => {

  const [isShowMobileFilters, setIsShowMobileFilters] = useState(false);
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const location = useLocation();
  const apiVersion = useRecoilValue(apiVersionState);
  useEffect(() => {
    async function bootstrap() {
      if (apiVersion) {
        const newProjects = await projectsApi.getProjects({ apiVersion });
        if (newProjects && newProjects.length > 0) {
          setProjects(newProjects);
        }
      }
    }
    bootstrap();
  }, [apiVersion, location]);
  const filterItems = filters.map((item, i) => {
    return (
      <span
        key={item.name}
        style={{}}
        onClick={() => setActiveFilterIndex(i)}
        className={cn(`marketplace__filter-item marketplace__filter-item_${i + 1}`,
          { 'marketplace__filter-item_active': activeFilterIndex === i },
        )}
      >
        {item.name}
      </span>
    );
  });

  return (
    <section id={'marketplace'} className={'marketplace'}>

      <div className='container marketplace__container'>

        <div className='marketplace__head'>
          <TitleWithIcon title={'Marketplace'} icon={<BagIcon />} />
          <span>Filters</span>

          <div className={'marketplace__mobile-filters-wrap'}>
            <BurgerButton
              openOrClose={() => setIsShowMobileFilters(!isShowMobileFilters)}
              isOpen={isShowMobileFilters}
            />

            <div
              className={cn('marketplace__filters-mobile',
                { 'marketplace__filters-mobile_active': isShowMobileFilters })}
            >
              {filterItems}
            </div>
          </div>
        </div>

        <div className='marketplace__filters'>
          {filterItems}
        </div>

        <div className='marketplace__projects-list'>
          {projects.map((project: any) => <BigCard project={project} key={project.id} />)}
        </div>

        <div className={'marketplace__slider-wrap'}>
          <Slider {...settingsForSlidersOnMainPage}>
            {projects.map((project: any) => <BigCard project={project} key={project.id} />)}
          </Slider>
        </div>

      </div>
    </section>
  );
};
