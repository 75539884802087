import './BestProjects.scss';
import { TitleWithIcon } from '../../../components/TitleWithIcon/TitleWithIcon';
import { ReactComponent as LikeSvg } from '../../../icons/like.svg';
import { ProjectCard } from '../../../components/ProjectCard/ProjectCard';
import { BigCard } from '../../../components/BigCard/BigCard';
import Slider from 'react-slick';
import { settingsForSlidersOnMainPage } from '../../../constants/settingsForSlidersOnMainPage';
import { useRecoilValue } from 'recoil';
import { apiVersionState } from '../../../states/apiVersionState';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { projectsApi } from '../../../api/projectsApi';

export const BestProjects = () => {
  const apiVersion = useRecoilValue(apiVersionState);
  const location = useLocation();
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    async function bootstrap() {
      if (apiVersion) {
        const newProjects = await projectsApi.getByTag({ apiVersion, tags: ['Best'] }).catch(() => false);
        if (newProjects && newProjects.length > 0) {
          setProjects(newProjects);
          console.log(newProjects);
          
        }
      }
    }
    bootstrap();
  }, [apiVersion, location]);
  
  if (projects.length === 0) return <></>
  
  return (
    <section className={'best-projects'} id='best-projects'>
      <div className='container'>

        <TitleWithIcon title={'Best projects'} icon={<LikeSvg />} />

        <div className='best-projects__flex'>
          {projects[0] && <BigCard project={projects[0]} />}
          {projects[1] && <BigCard project={projects[1]} />}
          {projects.slice(2).map((project: any) =>
            <ProjectCard project={project} key={project.id} />)}
        </div>

        <div className='best-projects__slider-wrap'>
          <Slider {...settingsForSlidersOnMainPage}>
            {projects.map((project: any) => <BigCard project={project} key={project.id} />)}
          </Slider>
        </div>

      </div>
    </section>
  );
};
