import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState } from "recoil";
import { projectsApi } from "./api/projectsApi";
import { versionApi } from "./api/versionApi";
import { BuyNow } from "./components/BuyNow/BuyNow";
import { Footer } from "./components/layouts/Footer/Footer";
import { Header } from "./components/layouts/Header/Header";
import { Modal } from "./components/layouts/Modal/Modal";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import Dashboard from "./pages/Dashboard/Dashboard";
import { MainPage } from "./pages/MainPage/MainPage";
import { Profile } from "./pages/Profile/Profile";
import { Project } from "./pages/Project/Project";
import { buyNowModalState } from "./states/BuyNowModalState";
import { apiVersionState } from "./states/apiVersionState";
import { isUserAdmin } from "./states/isUserAdmin";
import { projectsState } from "./states/projectsState";
import { authState } from "./states/authState";
import "./App.scss";
import B2B from "./pages/MainPage/B2B/B2B";

function App() {
  const { hash } = useLocation();
  const [isOpenBuyModal, setIsOpenBuyModal] = useRecoilState(buyNowModalState);
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const [authData, setAuthData] = useRecoilState(authState);
  const setProjects = useRecoilState(projectsState)[1];
  const [apiVersion, setApiVersion] = useRecoilState(apiVersionState);

  // Scroll to anchor
  useEffect(() => {
    if (hash) {
      const elem = document.getElementById(hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [hash]);

  useEffect(() => {
    if (localStorage.getItem("isAdmin") === "true") {
      setIsUserAdmin(true);
    }
  }, [setIsUserAdmin]);

  useEffect(() => {
    localStorage.setItem("isAdmin", `${isUserAdminState}`);
  }, [isUserAdminState]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const token_type = localStorage.getItem("token_type");
    if (token && token_type) {
      setAuthData({ token, token_type });
    }
  }, [setAuthData]);

  useEffect(() => {
    localStorage.setItem("access_token", authData.token);
    localStorage.setItem("token_type", authData.token_type);
  }, [authData]);

  useEffect(() => {
    async function bootstrap() {
      const apiV = await versionApi.getVersion();
      console.log("apiVersion", apiVersion);
      setApiVersion(apiV);
    }
    bootstrap();
  }, [setApiVersion]);

  useEffect(() => {
    async function bootstrap() {
      const projs = (await projectsApi.getProjects({ apiVersion })) as any[];
      setProjects(projs);
    }
    if (Boolean(apiVersion)) {
      bootstrap();
    }
  }, [apiVersion, setProjects]);

  return (
    <>
    <Routes>
      <Route path={"/b2b"} element={<B2B />} />

    </Routes>
      <Header />


      <Modal
        isOpen={!!isOpenBuyModal}
        maxWidth={"512px"}
        onClose={() => setIsOpenBuyModal(null)}
      >
        <BuyNow {...isOpenBuyModal!} onClose={() => setIsOpenBuyModal(null)} />
      </Modal>

      <Routes>
        <Route path={"/"} element={<MainPage />} />
        <Route path={"/profile"} element={<Profile />} />
        <Route path={"/project/:projectID"} element={<Project />} />
        <Route path={"/admin"} element={<AdminLogin />} />
        <Route path={"/dashboard"} element={<Dashboard />} />
      </Routes>
      <Footer />

      <ToastContainer position="bottom-right" theme="dark" />
    </>
  );
}

export default App;
