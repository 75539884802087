import { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import cn from 'classnames';
import Web3 from 'web3';
import { ReactComponent as CheckboxArrow } from '../../../../../icons/checkbox.svg';
import { ReactComponent as Arrow } from '../../../../../icons/arrow-blue.svg';
import { ConfirmButton } from '../../../../ConfirmButton/ConfirmButton';
import { Metrics } from '../../../../../data/fakeStages';
import { stagesState } from '../../../../../states/stagesState';
import { stagesApi } from '../../../../../api/stagesApi';
import { TStageData, finishStage, getStageFullInfo, startStage } from '../../../../../api/smart-contract';
import { apiVersionState } from '../../../../../states/apiVersionState';
import { projectState } from '../../../../../states/projectState';
import { authState } from '../../../../../states/authState';
import { currentStageState } from '../../../../../states/stageState';
import { TOKEN_CONTRACT_ADDRESS } from '../../../../../consts';
import './EditInterface.scss';

interface EditInterfaceProps {
  backToStages: () => void;
  stage: any;
}
const metrics = [Metrics.DAYS, Metrics.MONTHS, Metrics.YEARS];

export const EditInterface = ({ stage, backToStages }: EditInterfaceProps) => {
  const date = new Date();
  const tzOffset = date.getTimezoneOffset() * 1000 * 60;

  const { token, token_type } = useRecoilValue(authState);
  const apiVersion = useRecoilValue(apiVersionState);
  const project = useRecoilValue(projectState);
  const setStages = useRecoilState(stagesState)[1];
  const setCurrentStage = useRecoilState(currentStageState)[1];
  const [activeStage, setActiveStage] = useState<any>()
  const [currentStageWeb3, setCurrentStageWeb3] = useState<TStageData>();
  const [initialInputs, setInitialInputs] = useState(
    {
      goal: stage.number_of_tokens,
      discount: stage.discount,
      startDate: new Date()
        .toISOString()
        .substring(0, 16),
      finishDate: new Date(stage.finish_date.getTime() - tzOffset)
        .toISOString()
        .substring(0, 16),
      freeze: stage.freeze_time,
      amount: stage.max_coins_per_hand,
      startingPrice: stage.start_price_usd,
      atTheEndOfSale: stage.at_the_end_of_sale,
    }
  )

  // const initialInputs = 

  useEffect(() => {
    if (stage.byte_id) {
      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        const response = await getStageFullInfo({
          address: TOKEN_CONTRACT_ADDRESS,
          id: stage.byte_id,
          web3: web3,
        });
        
        setCurrentStageWeb3({
          amountStage: web3.utils.fromWei(response.amountStage, 'ether'),
          goal: web3.utils.fromWei(response.goal, 'ether'),
          discontPrice: web3.utils.fromWei(response.discontPrice, 'ether'),
          totalAmount: web3.utils.fromWei(response.totalAmount, 'ether'),
          refund: response.refund,
          start: response.start,
          lockTime: response.lockTime
        });

        console.log(web3.utils.fromWei(response.goal, 'ether'));

        setInitialInputs( {
          goal: web3.utils.fromWei(response.amountStage, 'ether'),
          discount: web3.utils.fromWei(response.discontPrice, 'ether'),
          startDate: new Date(stage.start_date.getTime())
            .toISOString()
            .substring(0, 16),
          finishDate: new Date(stage.finish_date.getTime() - tzOffset)
            .toISOString()
            .substring(0, 16),
          freeze: stage.freeze_time,
          amount: web3.utils.fromWei(response.totalAmount, 'ether'),
          startingPrice: stage.start_price_usd,
          atTheEndOfSale: stage.at_the_end_of_sale,
        });
      })();
    }

    // let aaa = new Date(new Date().getTime() + tzOffset + tzOffset)
    
    
  }, [stage])
  
  const [inputs, setInputs] = useState(() => {
    const years = Math.round(initialInputs.freeze / (60 * 60 * 24 * 365));
    const months = Math.round(initialInputs.freeze / (60 * 60 * 24 * 30));
    const days = Math.round(initialInputs.freeze / (60));
    if (years > 0) {
      return { ...initialInputs, freeze: years };
    }
    if (months > 0) {
      return { ...initialInputs, freeze: months };
    }
    return { ...initialInputs, freeze: days };
  });

  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);

  const [currentFreezeMetric, setCurrentFreezeMetric] = useState(() => {
    const freeze_time = stage.freeze_time;
    const years = Math.round(freeze_time / (60 * 60 * 24 * 365));
    const months = Math.round(freeze_time / (60 * 60 * 24 * 30));
    if (years > 0) {
      return Metrics.YEARS;
    }
    if (months > 0) {
      return Metrics.MONTHS;
    }
    return Metrics.DAYS;
  });

  useEffect(() => {
    async function bootstrap() {
      const currentStage = (await stagesApi.getProjectStages({
        apiVersion,
        projectId: project.id,
        isCurrent: true,
      })) as any[];

      console.log(currentStage);
      setActiveStage(currentStage);
    }
    if (apiVersion) {
      bootstrap();
    }   
  }, [apiVersion])

  const onConfirmButtonClick = async () => {
    const updateBody = {
      goal: inputs.goal,
      amount: inputs.amount,
      start_price_usd: inputs.startingPrice,
      discount: inputs.discount,
      start_date: new Date(inputs.startDate).toISOString(),
      finish_date: new Date(inputs.finishDate).toISOString(),
      freeze_time:
        inputs.freeze *
        60 *
        (currentFreezeMetric === Metrics.MONTHS ? 30 : 1) *
        (currentFreezeMetric === Metrics.YEARS ? 365 : 1),
      at_the_end_of_sale: inputs.atTheEndOfSale,
    };

    console.log(updateBody);

    const updateQuery = stagesApi.updateStage({
      apiVersion,
      projectId: project.id,
      stageId: stage.id,
      data: updateBody,
      access_token: token,
      token_type
    });

    toast.promise(updateQuery, {
      success: 'You have started stage!',
      error: 'Something is wrong. Please, try again',
      pending: 'Loading...',
    });

    const stages = await stagesApi.getProjectStages({
      apiVersion,
      projectId: project.id as string,
      isCurrent: false,
    });

    setStages(stages);

    const newStage = (
      (await stagesApi.getProjectStages({
        apiVersion,
        projectId: project.id as string,
        isCurrent: true,
      })) as any[]
    )[0];

    const provider = await window.ethereum;

    if (!provider) {
      toast('Smth went wrong', { type: 'error' });
      return;
    }

    const web3 = new Web3(provider);

    startStage({
      web3: web3,
      id: stage.byte_id,
      lockTime: updateBody.freeze_time,
      goal: updateBody.goal,
      discont: updateBody.discount * updateBody.start_price_usd / 100,
      amount: updateBody.amount,
      address: TOKEN_CONTRACT_ADDRESS,
      realTokenAddress: project.realTokenAddress,
      ccTokenAddress: project.ccTokenAddress
    }).then(() => {
      setCurrentStage(newStage);
      backToStages();
      toast('Project stage updated successfully', { type: 'success' });
    }).catch(async(e) => {
      await stagesApi.deleteStage({
        projectID: project.id,
        apiVersion,
        stageID: stage.id,
        access_token: token,
        token_type
      });

      const newStages = await stagesApi.getProjectStages({
        projectId: project.id,
        apiVersion,
        isCurrent: false,
      });

      setStages(newStages);

      const newStage = (
        (await stagesApi.getProjectStages({
          apiVersion,
          projectId: project.id as string,
          isCurrent: true,
        })) as any[]
      )[0];

      setCurrentStage(newStage);
      backToStages();
      toast('Stage deleted because of error in smart contract calling', { type: 'error' });
      toast(e.message, { type: 'error' });
    });
  };

  const onFinishButtonClick = async () => {
    const provider = await window.ethereum;
    if (!provider) {
      toast('Smth went wrong', { type: 'error' });
      return;
    }
    const web3 = new Web3(provider);
    
    finishStage({
      number: activeStage[0].byte_id,
      address: TOKEN_CONTRACT_ADDRESS,
      web3: web3 
    }).then(() => {
      
      toast('Project stage finished in smart contract', { type: 'success' });
      
      stagesApi.updateStage({
        apiVersion,
        projectId: project.id,
        stageId: stage.id,
        data: {finish_date: new Date(new Date().getTime() + tzOffset + tzOffset).toISOString()},
        access_token: token,
        token_type
      }).then(() => {
        backToStages();
        toast('Project stage finished', { type: 'success' });
      }).catch((e) => console.log(e));
      
    }).catch(async(e) => {
      toast('There is error with smart contract function call', { type: 'error' });
    })
  }

  const onDeleteButtonClick = async () => {
    await stagesApi.deleteStage({
      projectID: project.id,
      apiVersion,
      stageID: stage.id,
      access_token: token,
      token_type
    });
    const newStages = await stagesApi.getProjectStages({
      projectId: project.id,
      apiVersion,
      isCurrent: false,
    });
    setStages(newStages);
    const newStage = (
      (await stagesApi.getProjectStages({
        apiVersion,
        projectId: project.id as string,
        isCurrent: true,
      })) as any[]
    )[0];
    setCurrentStage(newStage);
    backToStages();
    toast('Project stage deleted successfully', { type: 'success' });
  };
  
  function inputsHandler(key: keyof typeof initialInputs) {
    return function(
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) {
      const value = event.target.value;
      setInputs((inputs) => ({ ...inputs, [key]: value }));
    };
  }

  return (
    <div className={'edit-interface'}>
      <div className={'input-title'}>Choose goal </div>
      <input
        type='number'
        placeholder={'Number of tokens'}
        className={'edit-interface__input'}
        value={inputs.goal}
        onChange={inputsHandler('goal')}
      />

      <div className={'edit-interface__input-title input-title'}>
        Choose amount
      </div>
      <input
        type='number'
        placeholder={'Number of tokens'}
        className={'edit-interface__input'}
        value={inputs.amount}
        onChange={inputsHandler('amount')}
      />
      <div className={'edit-interface__input-title input-title'}>
        Price
      </div>
      <input
        type='text'
        placeholder={'Price without discount'}
        className={'edit-interface__input'}
        value={inputs.startingPrice}
        onChange={inputsHandler('startingPrice')}
      />

      <div className={'edit-interface__input-title input-title'}>
        Choose a discount
      </div>
      <input
        type='text'
        placeholder={'50%'}
        className={'edit-interface__input edit-interface__input_discount'}
        value={inputs.discount}
        onChange={inputsHandler('discount')}
      />

      <div className={'edit-interface__input-title input-title'}>
        Choose a start date
      </div>
      <input
        type='datetime-local'
        placeholder={'Start date'}
        className={'edit-interface__input'}
        value={inputs.startDate}
        onChange={inputsHandler('startDate')}
      />
      <div className={'edit-interface__input-title input-title'}>
        Choose a finish date
      </div>
      <input
        type='datetime-local'
        placeholder={'Finish date'}
        className={'edit-interface__input'}
        value={inputs.finishDate}
        onChange={inputsHandler('finishDate')}
      />

      <div
        onClick={() =>
          setInputs({ ...inputs, atTheEndOfSale: !inputs.atTheEndOfSale })
        }
        className='edit-interface__checkbox-wrapper'
      >
        <div
          className={cn('edit-interface__checkbox', {
            'edit-interface__checkbox_active': inputs.atTheEndOfSale,
          })}
        >
          {inputs.atTheEndOfSale && <CheckboxArrow />}
        </div>
        At the end of the sale
      </div>

      <div className={'edit-interface__input-title input-title'}>
        Choose a freeze
      </div>
      <div className='edit-interface__freeze-inputs'>
        <input
          type='number'
          className={'edit-interface__input_freeze'}
          name={Object.keys(inputs)[4]}
          value={inputs.freeze}
          onChange={inputsHandler('freeze')}
          min={1}
        />

        <div className='freeze-metric'>
          <div
            onClick={() => setIsVisibleDropdown(!isVisibleDropdown)}
            className='freeze-metric__head'
          >
            {currentFreezeMetric} <Arrow />
          </div>

          <div
            className={cn('freeze-metric__dropdown', {
              'freeze-metric__dropdown_visible': isVisibleDropdown,
            })}
          >
            {metrics.map((metric) => {
              return (
                <div
                  key={metric}
                  className='freeze-metric__dropdown-item'
                  onClick={() => {
                    setCurrentFreezeMetric(metric);
                    setIsVisibleDropdown(false);
                  }}
                >
                  {metric}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ConfirmButton
        className={classNames('edit-interface__confirm-button', {"edit-interface__confirm-button_disabled": currentStageWeb3?.amountStage !== "0."})}
        onClick={onConfirmButtonClick}
      >
        CONFIRM
      </ConfirmButton>

      {activeStage && activeStage.length !== 0 && (
        <ConfirmButton
          className={'edit-interface__confirm-button'}
          onClick={onFinishButtonClick}
        >
          FINISH STAGE
        </ConfirmButton>
      )}

      <ConfirmButton
        className={'edit-interface__delete-button'}
        onClick={onDeleteButtonClick}
      >
        DELETE STAGE
      </ConfirmButton>
    </div>
  );
};
