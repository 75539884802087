import './Published.scss';
import Slider from 'react-slick';
import { TitleWithIcon } from '../../../components/TitleWithIcon/TitleWithIcon';
import { ReactComponent as WatchSvg } from '../../../icons/watch.svg';
// import { ReactComponent as ArrowSvg } from '../../../icons/slider-arrow.svg';
import { useRef } from 'react';
import { ProjectCard } from '../../../components/ProjectCard/ProjectCard';
import { NavArrows } from '../../../components/NavArrows/NavArrows';
import { useRecoilValue } from 'recoil';
import { projectsState } from '../../../states/projectsState';

export const Published = () => {
  const projects = useRecoilValue(projectsState);
  const sliderRef = useRef<Slider>(null);
  
  const settings = {
    dots: false,
    dotsClass: 'slider-wrap__nav-wrap',
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    initialSlide: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section id={'latest'} className={'published'}>
        <div className='container'>

          <div className='published__head'>
            <TitleWithIcon title={'Latest Projects'} icon={<WatchSvg/>}/>
            <NavArrows
              onLeftArrowClick={() => sliderRef.current && sliderRef.current.slickPrev()}
              onRightArrowClick={() => sliderRef.current && sliderRef.current.slickNext()}
            />
          </div>

          <div className='published__slider-wrap'>
            <Slider className={'slider-wrap__slider'} ref={sliderRef} {...settings}>
              {projects.map(project=>{
                return <ProjectCard
                  key={project.id}
                  project={project}
                />;
              })}
            </Slider>
          </div>

        </div>
      </section>
    </>
  );
};
