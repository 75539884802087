import React, { useState } from 'react';
import { ReactComponent as BitcoinIcon } from '../../icons/bitcoin-logo.svg';
import { ReactComponent as ShareButton } from '../../icons/share-button.svg';
import { ReactComponent as LogotypeSvg } from '../../icons/logotype.svg';
import { ProjectLink } from '../ProjectLink/ProjectLink';
import { isUserAdmin } from '../../states/isUserAdmin';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { EditButton } from '../EditButton/EditButton';
import { Modal } from '../layouts/Modal/Modal';
import { EditPopUpContainer } from '../layouts/Modal/EditPopUpContainer/EditPopUpContainer';
import { ImageUpload } from '../layouts/Modal/ImageUpload/ImageUpload';
import { projectState } from '../../states/projectState';
import { apiURL } from '../../constants/apiURL';
import './ProjectInfo.scss';
import { stagesApi } from '../../api/stagesApi';
import { apiVersionState } from '../../states/apiVersionState';
import { buyNowModalState } from '../../states/BuyNowModalState';
import { isAuthUser } from '../../states/isAuthUser';

export interface ProjectButtons {
  name?: string;
  link?: string;
  type?: 'link' | 'docs' | 'code' | 'community' | 'explore';
}

export const ProjectInfo = () => {
  const [isUser, setIsUser] = useRecoilState(isAuthUser);
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTags, setIsModalOpenTags] = useState(false);
  const project = useRecoilValue(projectState);
  const projectLinks: ProjectButtons[] = [
    {
      type: 'link',
      link: project.website_link,
      name: `${project.website_link && project.website_link.includes('http')
        ? new URL(project.website_link)
        : ''
        }`,
    },
    { type: 'docs', link: project.whitepaper_link, name: 'Whitepaper' },
    { type: 'code', link: project.tokenomics_link, name: 'Tokenomics' },
  ];
  const apiVersion = useRecoilValue(apiVersionState);
  const setIsOpenBuyModal = useSetRecoilState(buyNowModalState);
  const [currentStage, setCurrentStage] = useState(null);

  const onBuyClick = async () => {
    const data = await stagesApi.getProjectStages({
      apiVersion: apiVersion,
      isCurrent: true,
      projectId: project.id,
    });
    const activeStage = data[data.length - 1];
    setCurrentStage(activeStage)
    setIsOpenBuyModal({
      name: project.coin,
      price: activeStage.start_price_usd,
      stageNum: activeStage?.stage_num || 0,
      symbol: project.symbol ?? 'no symbol',
      discont: activeStage.discount,
      freezTime: activeStage.freeze_time,
      stageId: activeStage?.id || 0,
      tokenId: activeStage?.byte_id || 0,
    });
  };

  return (
    <div className='project-info'>
      <Modal isOpen={isModalOpen} maxWidth={'374px'}>
        <EditPopUpContainer
          title={'image upload'}
          onClose={() => setIsModalOpen(false)}
        >
          <ImageUpload />
        </EditPopUpContainer>
      </Modal>

      <Modal isOpen={isModalOpenTags} maxWidth={'374px'}>
        <EditPopUpContainer
          title={'Tags'}
          onClose={() => setIsModalOpenTags(false)}
        >
          <>
            <br />
            <div className="project-editor__tags">
              {project.tags &&
                project.tags.map((tag: string, index: number) => {
                  return (
                    <div key={index} className='project-editor__tag'>
                      {tag}
                    </div>

                  );
                })}
            </div>
          </>
        </EditPopUpContainer>
      </Modal>

      <div className='project-info__banner'>
        {isUserAdminState && (
          <EditButton
            className='project-info__banner-edit'
            isSmall
            onClick={() => setIsModalOpen(true)}
          />
        )}

        {project.project_image_path ? (
          <img
            src={`${apiURL}/static/projects/${project.id}/images/${project.project_image_path}`}
            alt='banner'
          />
        ) : (
          <div className='0'>
            <LogotypeSvg />
          </div>
        )}
      </div>
      <div className='project-info__content'>
        <div className='project-info__top'>
          <div className='project-info__logo'>
            {project.coin_image_path && (
              <img
                src={`${apiURL}/static/projects/${project.id}/images/${project.coin_image_path}`}
                alt='project_coin'
                style={{ width: '32px', height: '32px', borderRadius: '100%' }}
              />
            )}
            {/* <img
              src={'/images/btc.png'}
              alt='project_coin'
              style={{ width: '32px', height: '32px', borderRadius: '100%' }}
            /> */}
          </div>
          <div className='project-info__title'>{project.name}</div>
          <div className='project-info__extra'>
            <div className='project-info__chain'>{project.symbol}</div>
            {/* <div className='project-info__share'>
              <ShareButton />
            </div> */}
          </div>
        </div>
        <div className='project-info__buttons'>
          {projectLinks?.map((item: ProjectButtons, i) => {
            return (
              item.link &&
              item.link !== 'string' && (
                <ProjectLink
                  key={i}
                  link={item.link}
                  type={item.type}
                  name={item.name}
                />
              )
            );
          })}
        </div>
        <div className='project-info__tags-title'>Tags:</div>
        <div className='project-info__tags'>
          {project.tags &&
            project.tags.slice(0, 8).map((tag: string, index: number) => {
              return (
                <div className='project-info__tag' key={index}>
                  {tag}
                </div>
              );
            })}
          {project.tags && project.tags.length > 8 &&
            <div onClick={() => setIsModalOpenTags(true)} className='project-info__tag project-info__tag_view-all'>
              View all
            </div>
          }
        </div>

        <div
          onClick={isUser || isUserAdminState ? onBuyClick : () => { }}
          className='project-info__button-buy'
        >
          {isUser || isUserAdminState ? 'BUY NOW' : 'LOGIN FIRST'}

        </div>
      </div>
    </div>
  );
};
