import { useRecoilState, useRecoilValue } from "recoil";
import { projectState } from "../../../../states/projectState";
import { imagesApi } from "../../../../api/imagesApi";
import { projectsApi } from "../../../../api/projectsApi";
import { apiVersionState } from "../../../../states/apiVersionState";
import { apiURL } from "../../../../constants/apiURL";
import { useState } from "react";
import "./ImageUpload.scss";
import { toast } from "react-toastify";
import { authState } from "../../../../states/authState";

const widths = {
  profile: "210px",
  card: "265px",
};

const heights = {
  profile: "290px",
  card: "200px",
};

export const ImageUpload = () => {
  const [project, setProject] = useRecoilState(projectState);
  const apiVersion = useRecoilValue(apiVersionState);
  const [curImgType, setCurImgType] = useState<"profile" | "card" | undefined>(
    undefined
  );
  const [imgUrl, setImgUrl] = useState("");
  const [curFile, setCurFile] = useState<any>();
  const { token, token_type } = useRecoilValue(authState);
  function handleImageInput(imageType: "profile" | "card") {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.result) setImgUrl(fileReader.result as string);
      setCurImgType(imageType);
    };
    return async function (event: any) {
      const file = event.target.files ? event.target.files[0] : null;
      fileReader.readAsDataURL(file);
      setCurFile(file);
    };
  }

  async function uploadImg() {
    if (curFile && curImgType) {
      try {
        const formData = new FormData();
        formData.append("img", curFile);
        const { image } = await imagesApi.uploadImage({
          apiVersion,
          data: formData,
          imageType: curImgType,
          projectID: project.id,
          access_token: token,
          token_type
        });
        let imageKey = "project_image_path";
        if (curImgType === "card") {
          imageKey = "card_image_path";
        }
        await projectsApi.updateProject({
          apiVersion,
          data: { [imageKey]: image },
          projectID: project.id,
          access_token: token,
          token_type,
        });
        const newProject = await projectsApi.getProjectByID({
          apiVersion,
          projectID: project.id,
        });
        setProject(newProject);
        setCurFile(undefined);
        setCurImgType(undefined);
        toast.success("Successfully updated image");
      } catch (e) {
        toast.error("Error while uploading image");
      }
    }
  }

  return (
    <div className="image-upload">
      <div className="image-upload__content">
        {curImgType && (
          <>
            <div className="image-upload__item">
              <img
                src={imgUrl}
                alt="current_image"
                className="image-upload__image"
                style={{
                  width: curImgType && widths[curImgType],
                  height: curImgType && heights[curImgType],
                }}
              />
              <div style={{ display: "flex", gap: "10px" }}>
                <button className="image-upload__button" onClick={uploadImg}>
                  save
                </button>
                <button
                  className="image-upload__button image-upload__back"
                  onClick={() => setCurImgType(undefined)}
                >
                  back
                </button>
              </div>
            </div>
          </>
        )}
        {!curImgType && (
          <>
            <div className="image-upload__item">
              <p className="image-upload__text">
                Select a image that will be on the project page
                <br />
                <span className="image-upload__resolution">210x290px</span>
              </p>

              <img
                className="image-upload__image"
                src={`${apiURL}/static/projects/${project.id}/images/${project.project_image_path}`}
                alt="project"
                style={{ width: "210px", height: "290px" }}
              />

              <label
                className="image-upload__button"
                onChange={handleImageInput("profile")}
                htmlFor="firstInput"
              >
                UPLOAD FILE
                <input id="firstInput" hidden type="file" />
              </label>
            </div>
            <div className="image-upload__item">
              <p className="image-upload__text">
                Select a image that will be on the project card
                <br />
                <span className="image-upload__resolution">265x200px</span>
              </p>
              <img
                className="image-upload__image"
                src={`${apiURL}/static/projects/${project.id}/images/${project.card_image_path}`}
                alt="card"
                style={{ width: "265px", height: "200px" }}
              />
              <label
                className="image-upload__button"
                onChange={handleImageInput("card")}
                htmlFor="secondInput"
              >
                UPLOAD FILE
                <input id="secondInput" hidden type="file" />
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
