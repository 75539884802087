import './Modal.scss';
import React, { useEffect } from 'react';
import { BuyNowData } from '../../../states/BuyNowModalState';
import { stopScrollClass } from '../../../constants/basic';

interface ModalProps {
    maxWidth: string;
    isOpen: boolean | null | BuyNowData;
    onClose?: () => void;
    children: React.ReactNode
}

export const Modal = ({ isOpen, maxWidth, onClose, children }: ModalProps) => {

  useEffect(() => {
    if (isOpen) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.classList.add(stopScrollClass);
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.classList.remove(stopScrollClass);
      document.body.style.paddingRight = '0';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const onModalClick = ({ target, currentTarget }: React.MouseEvent) => {
    if (target === currentTarget) {
      onClose && onClose();
    }
  };

  return (
    <div onClick={onModalClick} className={'modal'}>
      <div style={{ maxWidth }} className={'modal__container'}>
        {children}
      </div>
    </div>
  );
};
