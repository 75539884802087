import { useState } from "react";
import Slider from "react-slick";
import { useRecoilState, useRecoilValue } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { stagesState } from "../../states/stagesState";
import { EditButton } from "../EditButton/EditButton";
import { EditPopUpContainer } from "../layouts/Modal/EditPopUpContainer/EditPopUpContainer";
import { Modal } from "../layouts/Modal/Modal";
import { StagesEditor } from "../layouts/Modal/StagesEditor/StagesEditor";
import "./Roadmap.scss";
import { stagesApi } from "../../api/stagesApi";
import { projectState } from "../../states/projectState";
import { apiVersionState } from "../../states/apiVersionState";
import { authState } from "../../states/authState";

export const Roadmap = ({ isEditable }: { isEditable?: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEditingStage, setCurrentEditingStage] = useState<any>(null);
  const project = useRecoilValue(projectState);
  const [stages, setStages] = useRecoilState(stagesState);
  const { token, token_type } = useRecoilValue(authState);
  
  const canEditStages =
    Boolean(project.ccTokenAddress) && Boolean(project.realTokenAddress);
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const curDate = new Date();
  const apiVersion = useRecoilValue(apiVersionState);

  const addStage = async () => {
    await stagesApi.addStage({
      data: {
        number_of_tokens: 0,
        max_coins_per_hand: 0,
        start_price_usd: 0,
        discount: 0,
        start_date: new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000
        ).toISOString(),
        finish_date: new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000
        ).toISOString(),
        at_the_end_of_sale: false,
        freeze_time: 0,
      },
      apiVersion,
      projectId: project.id,
      access_token: token,
      token_type
    });
    const newStages = await stagesApi.getProjectStages({
      apiVersion,
      projectId: project.id,
      isCurrent: false,
    });
    setStages(newStages);
    setCurrentEditingStage(newStages[newStages.length - 1]);
  };

  const roadmapItems = stages.map((item, i) => {
    return (
      <div key={i} className="roadmap__item roadmap-item">
        <div className="roadmap-item__title">Stage {item.stage_num}</div>

        <div className="roadmap-item__numbers-wrap">
          <div className="roadmap-item__old-price">${item.start_price_usd}</div>
          <div className="roadmap-item__current-price">
            $
            {(
              item.start_price_usd -
              (item.start_price_usd * item.discount) / 100
            ).toFixed(2)}
          </div>
          <div className="roadmap-item__sale">-{item.discount}%</div>
        </div>

        <div className="roadmap-item__finished">
          <div className="roadmap-item__finished-title">
            {curDate > item.start_date ? "finished" : "started"}
          </div>
          <div className="roadmap-item__finished-date">
            <div>
              {curDate > item.start_date
                ? (item.finish_date as Date).toLocaleDateString()
                : (item.finish_date as Date).toLocaleDateString()}
            </div>
            <div className="roadmap-item__finished-time">
              {(
                (curDate > item.start_date
                  ? item.finish_date
                  : item.finish_date) as Date
              ).toLocaleTimeString()}
            </div>
          </div>
        </div>

        <div className="roadmap-item__percent-wrap">
          <div>{item.sold}</div>
          <div className="roadmap-item__percent">{item.soldPercent}</div>
        </div>

        <div className="roadmap-item__bar">
          <div
            style={{ width: item.soldPercent }}
            className="roadmap-item__bar-line"
          />
        </div>
      </div>
    );
  });

  return (
    <div className="roadmap">
      {isEditable && (
        <>
          <Modal isOpen={isModalOpen} maxWidth={"340px"}>
            <EditPopUpContainer
              title={"Stages"}
              isArrowButton={Boolean(currentEditingStage)}
              onClose={() => setIsModalOpen(false)}
              clickOnBackButton={async () => {
                // await stagesApi.deleteStage({
                //   projectID: project.id,
                //   apiVersion,
                //   stageID: currentEditingStage.id,
                // });
                // const newStages = await stagesApi.getProjectStages({
                //   projectId: project.id,
                //   apiVersion,
                //   isCurrent: false,
                // });
                // setStages(newStages);
                setCurrentEditingStage(null);
              }}
            >
              {/* setCurrentEditingStage={(stage) => setCurrentEditingStage(stage)} */}
              <StagesEditor
                currentEditingStage={currentEditingStage}
                cancelEditingStage={() => setCurrentEditingStage(null)}
                setCurrentEditingStage={setCurrentEditingStage}
                onAddStage={addStage}
                stages={stages}
              />
            </EditPopUpContainer>
          </Modal>
          <div className="roadmap__edit-button-wrapper">
            <EditButton
              onClick={() => setIsModalOpen(true)}
              className={"roadmap__edit-button"}
              title={
                canEditStages ? "Edit stages" : "Enter valid contract addresses"
              }
              disabled={!canEditStages}
            />
          </div>
        </>
      )}

      <Slider {...settings}>{stages && roadmapItems}</Slider>
    </div>
  );
};
