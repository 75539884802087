import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { cardButtonsHeight } from '../../constants/basic';
import { buyNowModalState } from '../../states/BuyNowModalState';
import { CounterBlock } from '../CounterBlock/CounterBlock';
import './OtherCard.scss';

interface OtherCardProps {
  withButton?: boolean;
}

export const OtherCard = ({ withButton }: OtherCardProps) => {
  const setIsOpenBuyModal = useSetRecoilState(buyNowModalState);
  const cardRef = useRef<HTMLDivElement>(null);
  const [fullHeight, setFullHeight] = useState(0);

  useEffect(() => {
    if (cardRef.current) {
      setFullHeight(cardRef.current.offsetHeight);
    }
  }, [cardRef.current]);

  const onBuyClick = () => {
    //setIsOpenBuyModal({
    //  name: 'test',
    //  price: currentStage.start_price_usd,
    //  stageNum: currentStage.stage_num,
    //  symbol: 'test',
    //  discont: currentStage.discount,
    //  freezTime: currentStage.freeze_time,
    //});
  };

  return (
    <div
      style={{ height: withButton ? 'max-content' : `${fullHeight + cardButtonsHeight}px` }}
      className={'other-card__outer'}
    >
      <div ref={cardRef} className={'other-card'}>
        <div className='other-card__inner'>

          <div className='other-card__head'>
            THE PRESIDENTS
            <div>-50%</div>
          </div>

          <div className='other-card__image-wrapper'>
            <img src='/images/presidents.png' alt='Banner' />
          </div>

          <div className='other-card__prices'>
            <div className={'other-card__subtitle'}>Bitcoin Purchase Price <span>(BTC)</span></div>
            <div className={'other-card__current-price'}>$13,969.84</div>
            <div className={'other-card__old-price'}>$27,939.68</div>
          </div>

          <CounterBlock className={'other-card__counter'} fontSize={'18px'} />

          <div
            className={cn('other-card__button-wrap',
              { 'other-card__button-wrap_visible': withButton })}
          >
            <div onClick={onBuyClick} className='other-card__main-button'>
              BUY NOW
              <div>WITH 12 MONTH FREEZE</div>
            </div>
            <Link to={'/project'} className='other-card__more-button'>
              Read More
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};
