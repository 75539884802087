import './Tokenomic.scss';
import { Chart } from './Chart/Chart';

export const Tokenomic = () => {
  return (
    <div className={'tokenomic'}>
      <h2 className='tokenomic__title'>Tokenomic</h2>
      <Chart/>
    </div>
  );
};
