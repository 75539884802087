import { ReactComponent as CrossIcon } from '../../../../icons/cross.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../icons/arrow-back.svg';
import './EditPopUpContainer.scss';

type EditPopUpContainerProps = {
    title: string;
    onClose: () => void;
    children: JSX.Element;
    clickOnBackButton?: () => void;
    isArrowButton?: boolean;
}

export const EditPopUpContainer = ({
  title,
  onClose,
  clickOnBackButton,
  children,
  isArrowButton,
}:EditPopUpContainerProps) => {

  const closeButton = isArrowButton && clickOnBackButton
    ? <ArrowBackIcon className={'edit-popup-container__cross'} onClick={() => clickOnBackButton()}/>
    : <CrossIcon className={'edit-popup-container__cross'} onClick={() => onClose()}/>;

  return (
    <div className={'edit-popup-container'}>
      <div className={'edit-popup-container__inner'}>
        <div className='edit-popup-container__head editor-title'>
          {title}
          {closeButton}
        </div>
        {children}
      </div>
    </div>
  );
};
