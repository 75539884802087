import { useEffect, useState } from "react";
import { ConfirmButton } from "../../../ConfirmButton/ConfirmButton";
import { inputHandler, removeInput } from "../../../../functions/editor";
import { MinusButton } from "../../../MinusButton/MinusButton";
import { projectState } from "../../../../states/projectState";
import { useRecoilState, useRecoilValue } from "recoil";
import { projectsApi } from "../../../../api/projectsApi";
import { apiVersionState } from "../../../../states/apiVersionState";
import "./VideoEditor.scss";
import { toast } from "react-toastify";
import { authState } from "../../../../states/authState";
type TVideoEditorProps = {
  closeVideoEditor: () => void;
};
export const VideoEditor = ({ closeVideoEditor }: TVideoEditorProps) => {
  const [project, setProject] = useRecoilState(projectState);
  const apiVersion = useRecoilValue(apiVersionState);
  const [inputs, setInputs] = useState([""]);
  const { token, token_type } = useRecoilValue(authState);

  async function onConfirmButtonClick() {
    const projectID = project.id;
    const updateBody = { video_links: inputs };
    await projectsApi.updateProject({
      apiVersion,
      projectID,
      data: updateBody,
      access_token: token,
      token_type,
    });
    const newProject = await projectsApi.getProjectByID({
      apiVersion,
      projectID,
    });
    setProject(newProject);
    closeVideoEditor();
    toast("Project videos updated successfully", { type: "success" });
  }

  useEffect(() => {
    if (project) setInputs(project.video_links);
  }, [project]);

  const addVideoLink = () => setInputs((inputs) => [...inputs, ""]);

  const inputsItems = inputs.map((video, i) => {
    return (
      <div key={i} className={"video-editor__input-template"}>
        <div className="video-editor__input-title input-title">
          LINK {i + 1}
        </div>
        <input
          type="text"
          name={`video-input-${i}`}
          className={"edit-interface__input"}
          onChange={(event) => setInputs(inputHandler(event, inputs, i))}
          value={inputs[i]}
        />
        <MinusButton
          removeInput={() => setInputs((inputs) => removeInput(i, inputs))}
        />
      </div>
    );
  });

  return (
    <div className={"video-editor"}>
      <div className="video-editor__title">
        Paste the link of your <span>Youtube</span> video
      </div>
      {inputsItems}
      <div
        onClick={() => addVideoLink()}
        className="video-editor__add-new-link"
      >
        <div className="video-editor__plus-button" /> Add new link
      </div>
      <ConfirmButton
        className={"edit-interface__confirm-button"}
        onClick={onConfirmButtonClick}
      >
        CONFIRM
      </ConfirmButton>
    </div>
  );
};
