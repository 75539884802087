import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactComponent as LogotypeSvg } from '../../icons/logotype.svg';
import { buyNowModalState } from '../../states/BuyNowModalState';
import { CounterBlock } from '../CounterBlock/CounterBlock';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { apiURL } from '../../constants/apiURL';
import { apiVersionState } from '../../states/apiVersionState';
import { stagesApi } from '../../api/stagesApi';
import { isAuthUser } from '../../states/isAuthUser';
import { TStageData, getStageFullInfo } from '../../api/smart-contract';
import { TOKEN_CONTRACT_ADDRESS } from '../../consts';
import { isUserAdmin } from '../../states/isUserAdmin';
import './BigCard.scss';

type TBigCardProps = {
  project: any;
};

type TCurrentStage = {
  discount: number,
  byte_id: number,
  start_price_usd: number,
  freeze_time: number,
  start_date: Date,
  finish_date: Date,
  at_the_end_of_sale: boolean,
  number_of_tokens: number,
  stage_num: number,
};

function truncateText(text: string) {
  const words = text.split(' ');
  const truncatedWords = words.slice(0, 30);
  const truncatedText = truncatedWords.join(' ');
  
  if (words.length > 30) {
    return truncatedText + '...';
  }
  
  return truncatedText;
}

export const BigCard = ({ project }: TBigCardProps) => {
  const [isUser, setIsUser] = useRecoilState(isAuthUser);
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const setIsOpenBuyModal = useSetRecoilState(buyNowModalState);
  const apiVersion = useRecoilValue(apiVersionState);
  const location = useLocation();

  const [timeDiff, setTimeDiff] = useState(0);

  const [currentStage, setCurrentStage] = useState<TCurrentStage | null>(null);
  const [currentStageWeb3, setCurrentStageWeb3] = useState<TStageData>();

  const onBuyClick = async () => {
    const data = await stagesApi.getProjectStages({
      apiVersion: apiVersion,
      isCurrent: true,
      projectId: project.id,
    });

    const activeStage = data[data.length - 1];

    if (currentStage) {
      setIsOpenBuyModal({
        name: project.coin,
        price: currentStage.start_price_usd,
        stageNum: activeStage?.stage_num || 0,
        symbol: project.symbol ?? 'no symbol',
        discont: currentStage.discount,
        freezTime: currentStage.freeze_time,
        stageId: activeStage?.id || 0,
        tokenId: activeStage?.byte_id || 0,
      });
    }
  };

  const days = currentStage ? currentStage.freeze_time / 1000 / 60 / 24 : 0;
  const months = days / 30;
  const years = days / 365;
  const isYears = years >= 1;
  const isMonths = months >= 1;

  useEffect(() => {
    async function bootstrap() {
      const newStage = (
        await stagesApi.getProjectStages({
          apiVersion,
          projectId: project.id,
          isCurrent: true,
        })
      )[0];
      if (newStage) {
        setCurrentStage(newStage);
      }
    }
    bootstrap();
  }, [apiVersion, location, project]);

  useEffect(() => {
    if (currentStage) {
      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        const response = await getStageFullInfo({
          address: TOKEN_CONTRACT_ADDRESS,
          id: currentStage.byte_id,
          web3: web3,
        });

        setCurrentStageWeb3({
          amountStage: web3.utils.fromWei(response.amountStage, 'ether'),
          goal: web3.utils.fromWei(response.goal, 'ether'),
          discontPrice: web3.utils.fromWei(response.discontPrice, 'ether'),
          totalAmount: web3.utils.fromWei(response.totalAmount, 'ether'),
          refund: response.refund,
          start: response.start,
          lockTime: response.lockTime
        });
      })();

      const interval = setInterval(() => {
        const finishTime = new Date(currentStage.finish_date);
        const startDate = new Date(currentStage.start_date);
        const date = new Date();
        if (!(date > finishTime) && !(date < startDate)) {
          setTimeDiff(
            new Date(currentStage.finish_date).getTime() - new Date().getTime(),
          );
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentStage]);

  return (
    <div className={'big-card'}>
      <div className='big-card__inner'>
        {currentStage && <div className={'big-card__main-tag'}> ONGOING </div>}

        <div className='big-card-left'>
          <div className='big-card-left__cover-wrap'>
            <div className='big-card-left__cover-info'>
              <ul className={'big-card-left__cover-tags'}>
              {project.tags.slice(0, 7).map((tag: string) => (
                  <li className={'big-card-left__cover-tag'} key={tag}>
                  {tag}
                </li>
                ))}
              </ul>
              {/* <div className='big-card-left__cover-percent'> -{currentStage?.discount} % </div> */}
            </div>

            {project.project_image_path ? (
              <img
                className={'big-card-left__cover'}
                src={`${apiURL}/static/projects/${project.id}/images/${project.project_image_path}`}
                alt='Cover'
                width='198px'
                height='145px'
              />
            ) :
              <div className='big-card-left__cover big-card-left__cover_empty'>
                <LogotypeSvg />
              </div>
            }
          </div>

          <h3 className={'big-card-left__mobile-title'}> {project.name} </h3>

          {currentStage && (
            <>
              <div className='big-card-left__buy-text'>
                {' '}
                Buy Price(ETH){' '}
              </div>

              <div className='big-card-left__main-info'>
                <div className='big-card-left__prices-wrap'>

                  <div className={'big-card-left__price'}>
                    {' '}
                    $
                    {currentStageWeb3?.discontPrice}
                  </div>

                  <div className={'big-card-left__old-price'}>
                    {' '}
                    ${currentStage && currentStage.start_price_usd.toFixed(2)}{' '}
                  </div>
                </div>

                <div className='big-card-left__discount'>
                  {' '}
                  {currentStage ? `-${currentStage.discount} %` : ''}
                  {/* -{currentStage.discount} %{' '} */}
                </div>
                <div className='big-card-left__freeze'>
                  {Math.round(isYears ? years : isMonths ? months : days)}&nbsp;
                  {isYears ? 'YEARS' : isMonths ? 'MONTHS' : 'DAYS'} FREEZE
                </div>
              </div>
            </>
          )}
          <div className='big-card-left__calculating'>
            {/*<div className='big-card-left__supply'>Circulating Supply<CheckSvg/></div>*/}

            {currentStageWeb3 && (
              <>
                <div className='big-card-left__percent'>
                  <span>{Number(currentStageWeb3?.totalAmount)} USDT</span>
                  {currentStageWeb3 && Number(currentStageWeb3?.totalAmount) * 100 / Number(currentStageWeb3?.goal)}%
                </div>

                <div className='big-card-left__bar'>
                  <div className='big-card-left__bar_inner-line' style={{ width: `${Number(currentStageWeb3?.totalAmount) * 100 / Number(currentStageWeb3?.goal)}%` }} />
                </div>
              </>
            )}

            <div
              onClick={isUser || isUserAdminState ? onBuyClick : () => { }}
              className='big-card-left__main-button main-button'
            >
              {isUser || isUserAdminState ? currentStage ? 'BUY NOW' : "NO STAGE" : ' LOGIN FIRST'}
            </div>

            {/*<div className='big-card-left__max'><span>Max Supply <InfoSvg/></span> 21,000,000</div>*/}
            {/*<div className='big-card-left__total'><span>Total Supply <InfoSvg/></span> 19,339,562</div>*/}
            {/*<div className='big-card-left__mobile-supply'>*/}
            {/*  <span>Circulating Supply <InfoSvg/></span> 21,000,000*/}
            {/*</div>*/}

            <div className='big-card-left__mobile-socials'>
              {currentStage && (
                <ProgressBar
                  max={currentStageWeb3 ? Number(currentStageWeb3.goal) : 0}
                  current={currentStageWeb3 ? Number(currentStageWeb3.amountStage) : 0}
                  high={true}
                />
              )}
              {/*<Socials/>*/}
            </div>

            <div className='big-card-left__mobile-buttons'>
              <div
                onClick={isUser || isUserAdminState ? onBuyClick : () => { }}
                className={
                  'big-card-left__mobile-button big-card-left__mobile-button_buy'
                }
              >
                {isUser || isUserAdminState ? currentStage ? 'BUY NOW' : "NO STAGE" : ' LOGIN FIRST'}
              </div>
              <Link
                to={`/project/${project.id}`}
                className={
                  'big-card-left__mobile-button big-card-left__mobile-button_read'
                }
              >
                Read More
              </Link>
            </div>
          </div>
        </div>

        <div className='big-card-right'>
          <ul className={'big-card-right__tags'}>
            {project.tags.slice(0, 7).map((tag: string) => (
              <li className={'big-card-right__tag'} key={tag}>
                {tag}
              </li>
            ))}
          </ul>
          <h3 className={'big-card-right__title'}> {project.name} </h3>
          <p className={'big-card-right__description'}>
            {project.descriptions[0] ? truncateText(project.descriptions[0].description) : "There is no description for this project"}
            {/* this block include information about this project case this block
            include this block include information about this roject case this
            block includethis block include information project case this block
            includethis block include information */}
          </p>
          <div className='big-card-right__more-wrap'>
            <Link
              to={`/project/${project.id}`}
              className={'big-card-right__read-more'}
            >
              {' '}
              Read More{' '}
            </Link>
            <div className={'big-card-right__freeze'}>
              {Math.round(isYears ? years : isMonths ? months : days)}&nbsp;
              {isYears ? 'YEARS' : isMonths ? 'MONTHS' : 'DAYS'} FREEZE
            </div>
            {/*<Socials/>*/}
          </div>
          {currentStageWeb3 && (
            <CounterBlock
              className={'big-card-right__counter-block'}
              hasProgressBar
              tokens={currentStageWeb3 ? Number(currentStageWeb3.goal) / Number(currentStageWeb3.discontPrice) : 0}
              tokensCurrent={currentStageWeb3 ? Number(currentStageWeb3.totalAmount) / Number(currentStageWeb3.discontPrice) : 0}
              label='DISTRIB.'
              timeDiff={timeDiff}
            />
          )}
        </div>
      </div>
    </div>
  );
};
