import cn from 'classnames';
import { ChangeEvent, useState } from 'react';
import { inputHandler } from '../../../../functions/editor';
import { ReactComponent as UploadSvg } from '../../../../icons/upload.svg';
import { ConfirmButton } from '../../../ConfirmButton/ConfirmButton';
import { toast } from 'react-toastify';
import './ProjectEditor.scss';
import { imagesApi } from '../../../../api/imagesApi';
import { useRecoilValue } from 'recoil';
import { apiVersionState } from '../../../../states/apiVersionState';
import { apiURL } from '../../../../constants/apiURL';
import { authState } from '../../../../states/authState';

type TProjectEditorProps = {
  closeProjectEditor: () => void;
  editTags: boolean;
  onEditTags: () => void;
  editToken: boolean;
  onEditToken: () => void;
  project?: any;
  onEditConfirm?: (project: any) => void;
  onBack: () => void;
};

const possibleTags = [
  "Ethereum",
  "Polygon",
  "BSC",
  "Arbitrum",
  "Cronos",
  "Avalanch",
  "Klaytn",
  "Canto",
  "Wallets",
  "New project",
  "Ongoing",
  "LP",
  "PrCoins",
  "Lending Platforms",
  "L2S",
  "Exchanges",
  "Contracts",
  "Layer 2 Solutions",
  "Tokenized Assets",
  "TA",
  "NFT",
  "GTokens",
  "Privacy Coins",
  "Cross-chain",
  "Stacking",
  "Tokenized Real Estate",
  "TRE",
  "Governance Tokens",
  "GameFi",
  "Tokens",
  "Oracles",
  "Mining",
  "DApps",
  "Stable",
  "Meta",
  "DeFi",
  "ICP",
  "DAO",
  "STO",
  "Best",
  "Top Sales",
];

function setArrayItem<T>(index: number, value: T, array: T[]) {
  if (typeof value === "undefined") {
    return array.slice(0, index).concat(array.slice(index + 1));
  }
  return array.slice(0, index).concat([value, ...array.slice(index + 1)]);
}

export const ProjectEditor = ({
  closeProjectEditor,
  editTags,
  onEditTags,
  project,
  onEditConfirm,
  editToken,
  onEditToken,
  onBack,
}: TProjectEditorProps) => {
  const apiVersion = useRecoilValue(apiVersionState);
  const [projectEdit, setProjectEdit] = useState(project);
  const [tokenImgUrl, setTokenImgUrl] = useState<string | undefined>();
  const { token, token_type } = useRecoilValue(authState);
  const onAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setTokenImgUrl(fileReader.result as string);
      };
      const file = event.target.files[0];
      try {
        const data = new FormData();
        data.append("img", file);
        const { image } = await imagesApi.uploadImage({
          apiVersion,
          imageType: "coin",
          data,
          projectID: project.id,
          access_token: token,
          token_type
        });
        setProjectEdit((edit: any) => ({ ...edit, coin_image_path: image }));
        fileReader.readAsDataURL(file);
        onBack();
      } catch (e) {
        toast.error("Error while uploading image!");
      }
    }
  };

  const onTagEdit = (tag: string) => {
    return () => {
      const tagset = new Set(projectEdit.tags);
      if (tagset.has(tag)) {
        tagset.delete(tag);
        setProjectEdit({ ...projectEdit, tags: Array.from(tagset) });
        return;
      }
      tagset.add(tag);
      setProjectEdit({ ...projectEdit, tags: Array.from(tagset) });
    };
  };

  if (editToken) {
    return (
      <div className="token-editor">
        <span className="token-editor__text">
          Select a image that will be on the project page&nbsp;
          <span className="token-editor__text_blue">32x32px</span>
        </span>
        <label htmlFor="file-upload" className="token-editor__upload-btn">
          upload file
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={onAvatarChange}
            id="file-upload"
          />
        </label>
      </div>
    );
  }

  if (editTags) {
    return (
      <div className="tag-editor">
        {possibleTags.map((tag) => {
          return (
            <div
              className={cn("tag-editor__tag-btn", {
                "tag-editor__tag-btn_active": new Set(projectEdit.tags).has(
                  tag
                ),
              })}
              onClick={onTagEdit(tag)}
              key={tag}
            >
              {tag}
            </div>
          );
        })}
      </div>
    );
  }

  async function confirmEdit() {
    onEditConfirm?.(projectEdit);
    closeProjectEditor();
  }

  return (
    <div className={"project-editor"}>
      <div className={"input-title"}>Project name</div>
      <input
        type="text"
        name={"name"}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.name}
        onChange={(event) =>
          setProjectEdit(inputHandler<any>(event, projectEdit))
        }
      />

      <div className="project-editor__coin">
        <div className="project-editor__coin-name">
          <div className="input-title">Coin name</div>
          <input
            type="text"
            name={"coin"}
            className={"project-editor__coin-input"}
            value={projectEdit.coin}
            onChange={(event) =>
              setProjectEdit(inputHandler<any>(event, projectEdit))
            }
          />
        </div>
        <div className="project-editor__coin-upload">
          <div className="input-title">Coin image</div>
          <div className="project-editor__coin-container">
            <div className="project-editor__coin-image-wrapper">
              <img
                src={
                  !Boolean(tokenImgUrl)
                    ? `${apiURL}/static/projects/${project.id}/images/${project.coin_image_path}`
                    : tokenImgUrl
                }
                alt="Coin logo"
              />
            </div>
            <div onClick={onEditToken}>
              <UploadSvg />
            </div>
          </div>
        </div>
      </div>
      <div className={"input-title"}>Symbol</div>
      <input
        type="text"
        name={"symbol"}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.symbol}
        onChange={(event) =>
          setProjectEdit(inputHandler<any>(event, projectEdit))
        }
      />
      <div className="project-editor__title editor-title">Links</div>

      <div className={"input-title"}>whitepaper</div>
      <input
        type="text"
        placeholder={"Your link..."}
        name={"whitepaper_link"}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.whitepaper_link}
        onChange={(event) =>
          setProjectEdit(inputHandler<any>(event, projectEdit))
        }
      />

      <div className={"input-title"}>Tokenomics</div>
      <input
        type="text"
        placeholder={"Your link..."}
        name={"tokenomics_link"}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.tokenomics_link}
        onChange={(event) =>
          setProjectEdit(inputHandler<any>(event, projectEdit))
        }
      />

      <div className={"input-title"}>Website</div>
      <input
        type="text"
        placeholder={"Your link..."}
        name={"website_link"}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.website_link}
        onChange={(event) =>
          setProjectEdit(inputHandler<any>(event, projectEdit))
        }
      />

      <div className={"input-title"}>Community</div>
      <input
        type="text"
        placeholder={"Your link..."}
        className={"project-editor__input edit-interface__input"}
        value={projectEdit.community_links[0]}
        onChange={(event) =>
          setProjectEdit({
            ...projectEdit,
            community_links: setArrayItem(
              0,
              event.target.value,
              projectEdit.community_links
            ),
          })
        }
      />

      {projectEdit.community_links.length > 1 && (
        <>
          <div className={"input-title"}>Community</div>
          <input
            type="text"
            placeholder={"Your link..."}
            className={"project-editor__input edit-interface__input"}
            value={projectEdit.community_links[1]}
            onChange={(event) => {
              setProjectEdit({
                ...projectEdit,
                community_links: setArrayItem(
                  1,
                  event.target.value,
                  projectEdit.community_links
                ),
              });
            }}
          />
        </>
      )}

      <div
        onClick={() => {
          if (projectEdit.community_links.length > 1) {
            setProjectEdit({
              ...projectEdit,
              community_links: setArrayItem(
                1,
                undefined,
                projectEdit.community_links
              ),
            });
            return;
          }
          setProjectEdit({
            ...projectEdit,
            community_links: setArrayItem(1, "", projectEdit.community_links),
          });
        }}
        className={cn(
          "description-editor__plus-button project-editor__plus project-editor__plus_mt",
          {
            "description-editor__plus-button_line":
              projectEdit.community_links.length > 1,
          }
        )}
      />

      <div className="project-editor__title project-editor__title_tags editor-title">
        Tags
      </div>

      <div className="project-editor__tags">
        {projectEdit.tags.map((item: string) => (
          <div className="project-editor__tag">{item}</div>
        ))}

        <div
          className="description-editor__plus-button project-editor__plus"
          onClick={onEditTags}
        />
      </div>

      <ConfirmButton
        className="edit-interface__confirm-button"
        onClick={confirmEdit}
      >
        CONFIRM
      </ConfirmButton>
    </div>
  );
};
