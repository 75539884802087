import './BurgerButton.scss';
import cn from 'classnames';

interface BurgerButtonProps {
    openOrClose: () => void;
    isOpen: boolean;
}

export const BurgerButton = ({ openOrClose, isOpen }:BurgerButtonProps) => {
  return (
    <div
      onClick={() => openOrClose()}
      className={cn('burger-button', { 'burger-button_cross': isOpen })}
    />
  );
};
