import React from 'react'
import { ConfirmButton } from '../../../../components/ConfirmButton/ConfirmButton';
import { ReactComponent as MainPicture } from './../../../../icons/b2b-main.svg';
import { ReactComponent as Figure } from './figure.svg';
import "./B2BMain.scss"

const B2BMain = () => {
  return (
    <div className='b2b-main'>
      <Figure className='b2b-main__figure' />
      <div className="container">

        <div className="b2b-main__content">
          <div className="b2b-main__info">
            <div className="b2b-main__title">Take <span className='b2b-main__title_blue'>your project</span> to new heights</div>
            <div className="b2b-main__text">Attract investments and strengthen the community by selling tokens at a discount with CoinsClub</div>
            <ConfirmButton 
              className="b2b-main__button"
              onClick={() => console.log('a')}
            >Get started</ConfirmButton>
          </div>
          <div className="b2b-main__picture">
            <MainPicture className='b2b-main__svg' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default B2BMain