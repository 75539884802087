import './ConfirmButton.scss';
import cn from 'classnames';
interface ConfirmButtonProps {
  className?: string;
  children: string;
  onClick: () => void;
}
export const ConfirmButton = ({ className, onClick, children }: ConfirmButtonProps) => {
  return (
    <div onClick={() => onClick()} className={cn('confirm-button main-button', className)}>
      {children}
    </div>
  );
};
