import React, { ChangeEvent, useEffect, useState } from "react";
import { ConfirmButton } from "../../../ConfirmButton/ConfirmButton";
import { projectState } from "../../../../states/projectState";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";
import "./DescriptionEditor.scss";
import { projectsApi } from "../../../../api/projectsApi";
import { apiVersionState } from "../../../../states/apiVersionState";
import { toast } from "react-toastify";
import { authState } from "../../../../states/authState";

// const initialInputs = {
//   titleFirst: '',
//   descriptionFirst: '',
//   titleSecond: '',
//   descriptionSecond: '',
// };
type TDescriptionEditorProps = {
  closeDescriptionEditor: () => void;
};

export const DescriptionEditor = ({
  closeDescriptionEditor,
}: TDescriptionEditorProps) => {
  const [project, setProject] = useRecoilState(projectState);
  const [inputs, setInputs] = useState([{ header: "", description: "" }]);
  const apiVersion = useRecoilValue(apiVersionState);
  const { token, token_type } = useRecoilValue(authState);

  function inputsHandler(index: number, type: "header" | "description") {
    return function (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
      const value = event.target.value;
      const newInputs = inputs.map((input, idx) => {
        const newInput = { ...input };
        if (index === idx) {
          newInput[type] = value;
        }
        return newInput;
      });
      setInputs(newInputs);
    };
  }

  useEffect(() => {
    if (project && (project as Object).hasOwnProperty("descriptions"))
      setInputs(project.descriptions);
  }, [project]);

  const onConfirmButtonClick = async () => {
    const projectID = project.id;
    const updateBody = { descriptions: inputs };
    await projectsApi.updateProject({
      apiVersion,
      projectID,
      data: updateBody,
      access_token: token,
      token_type,
    });
    const newProject = await projectsApi.getProjectByID({
      apiVersion,
      projectID,
    });
    setProject(newProject);
    closeDescriptionEditor();
    toast("Project descriptions updated successfully", { type: "success" });
  };

  const addDescription = () => {
    const newInputs = inputs.concat([{ header: "", description: "" }]);
    setInputs(newInputs);
  };

  const removeDescription = (index: number) => {
    return function () {
      const newInputs = Array.from(inputs);
      setInputs(newInputs.slice(0, index).concat(newInputs.slice(index + 1)));
    };
  };

  return (
    <div className={"description-editor"}>
      {inputs.map((input, index, arr) => {
        return (
          <>
            <div key={index} className="description-editor__inputs-wrapper">
              <div className={"input-title"}>Header</div>
              <input
                type="text"
                placeholder={"Title..."}
                className={"description-editor__input"}
                name={Object.keys(inputs)[0]}
                value={input.header}
                onChange={inputsHandler(index, "header")}
              />
              <div className={"input-title"}>description</div>
              <textarea
                className={"description-editor__textarea"}
                placeholder={"Type something..."}
                name={Object.keys(inputs)[1]}
                value={input.description}
                onChange={inputsHandler(index, "description")}
              />
            </div>
            {arr.length > 1 && (
              <button
                onClick={removeDescription(index)}
                className="description-editor__plus-button description-editor__plus-button_line"
              />
            )}
          </>
        );
      })}
      <div>
        <button
          style={inputs.length > 1 ? { marginTop: "20px" } : {}}
          onClick={addDescription}
          className={cn("description-editor__plus-button")}
        />
      </div>
      <ConfirmButton onClick={onConfirmButtonClick}>CONFIRM</ConfirmButton>
    </div>
  );
};
