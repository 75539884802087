import cn from "classnames";
import { ReactComponent as PencilSvg } from "../../icons/pencil.svg";
import "./EditButton.scss";

interface EditButtonProps {
  title?: string;
  onClick: () => void;
  className?: string;
  isSmall?: boolean;
  disabled?: boolean;
}

export const EditButton = ({
  title,
  onClick,
  className,
  isSmall,
  disabled,
}: EditButtonProps) => {
  return (
    <div
      onClick={() => !disabled && onClick()}
      className={cn("edit-button", className, {
        "edit-button_small": isSmall,
        "edit-button_disabled": disabled,
      })}
    >
      <PencilSvg />
      {title}
    </div>
  );
};
