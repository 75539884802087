import './Explore.scss';
import { Link } from 'react-router-dom';

export const Explore = () => {
  return (
    <section className={'explore'}>
      <div className='container explore__container'>
        <h2 className={'explore__title'}>Get more for less with DexCounter</h2>
        <div className={'explore__subtitle'}>Discounted investments for innovative minds</div>
        <Link to={'/#marketplace'} className={'explore__button'}>Explore marketplace</Link>
      </div>
    </section>
  );
};
