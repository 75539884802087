import { ReactComponent as TwitterSvg } from '../icons/twitter.svg';
import { ReactComponent as TelegramSvg } from '../icons/telegram.svg';
import { ReactComponent as YoutubeSvg } from '../icons/youtube.svg';
import { ReactComponent as PlanetSvg } from '../icons/planet.svg';

interface Interface {
    name: string;
    link: string;
    icon: JSX.Element;
}

export const socialList: Interface[] = [
  {
    name: 'Twitter',
    link: '',
    icon: <TwitterSvg/>,
  },
  {
    name: 'Telegram',
    link: '',
    icon: <TelegramSvg/>,
  },
  {
    name: 'Youtube',
    link: '',
    icon: <YoutubeSvg/>,
  },
  {
    name: 'Planet',
    link: '',
    icon: <PlanetSvg/>,
  },
];
