import './MainPage.scss';
import { Explore } from './Explore/Explore';
import { Published } from './Published/Published';
import { Gift } from './Gift/Gift';
import { TopSales } from './TopSales/TopSales';
import { BestProjects } from './BestProjects/BestProjects';
import { Marketplace } from './Marketplace/Marketplace';

// wagmi.connected

export const MainPage = () => {
  return (
    <main className={'main-page'}>
      <Explore/>
      <Published/>
      <TopSales/>
      <Gift/>
      <BestProjects/>
      <Marketplace/>
    </main>
  );
};
