import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowSvg } from '../../../icons/read-more-arrow.svg';
import { EditButton } from '../../../components/EditButton/EditButton';
import './ProjectDescription.scss';
import { Modal } from '../../../components/layouts/Modal/Modal';
import { EditPopUpContainer } from '../../../components/layouts/Modal/EditPopUpContainer/EditPopUpContainer';
import { DescriptionEditor } from '../../../components/layouts/Modal/DescriptionEditor/DescriptionEditor';
import { useRecoilValue } from 'recoil';
import { projectState } from '../../../states/projectState';

const maxParagraphHeight = 96;

export const ProjectDescription = ({ isEditable }: {isEditable: boolean}) => {
  const project = useRecoilValue(projectState);
  const hidingTextRef = useRef<HTMLParagraphElement>(null);
  const [textHeight, setTextHeight] = useState(0); //высота текста
  const [isHiding, setIsHiding] = useState(false);  //текст открывающийся или обычный
  const [isOpenText, setIsOpenText] = useState(false); // окрыт или закрыт
  const [visibleTextHeight, setVisibleTextHeight] = useState(maxParagraphHeight); //видимая высота текста
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    hidingTextRef.current && setTextHeight(hidingTextRef.current.offsetHeight);
  }, [hidingTextRef]);

  useEffect(() => {
    hidingTextRef.current && setIsHiding(textHeight > maxParagraphHeight);
  }, [textHeight]);

  useEffect(() => {
    setVisibleTextHeight(isOpenText ? textHeight : maxParagraphHeight);
  }, [isOpenText]);

  return (
    <div className='project-description container'>

      <Modal isOpen={isModalOpen} maxWidth={'627px'}>
        <EditPopUpContainer title={'Description'} onClose={() => setIsModalOpen(false)}>
          <DescriptionEditor closeDescriptionEditor={() => setIsModalOpen(false)}/>
        </EditPopUpContainer>
      </Modal>

      {isEditable && (
        <EditButton
          className={'project-description__edit-button'}
          onClick={() => setIsModalOpen(true)}
          title={'Edit description'}
        />
      )}
      <div className='project-description__description'>
        {project.descriptions && (project.descriptions as any[]).map((description, index, arr)=>{
          if(index !== arr.length - 1){
            return <React.Fragment key={index}>
              <p className='project-description__description-title'>{description.header}</p>
              <p className='project-description__description-text'>{description.description}</p>
            </React.Fragment>;
          }
          return <React.Fragment key={index}>
            <p className='project-description__description-title'>{description.header}</p>
            <div
              style={{ height: isHiding ? `${visibleTextHeight}px` : 'auto' }}
              className='project-description__hidden-text-wrap'
            >
              <p ref={hidingTextRef} className='project-description__description-text'>
                {description.description}
              </p>
              <div
                className={cn('project-description__description-shadow',
                  { 'project-description__description-shadow_visible': isHiding && !isOpenText })}
              />
            </div>
          </React.Fragment>;
        })}

     

        <div
          className={
            cn('project-description__read-more',
              { 'project-description__read-more_hidden': !isHiding })
          }
          onClick={() => setIsOpenText(!isOpenText)}
        >
            Read More
          <ArrowSvg style={{ transform: `rotate(${isOpenText ? '180' : '0'}deg)` }}/>
        </div>
      </div>
    </div>
  );
};
