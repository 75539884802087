import React, { useEffect, useState } from "react";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { isUserAdmin } from "../../states/isUserAdmin";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { authApi } from "../../api/authApi";
import { apiVersionState } from "../../states/apiVersionState";
import { authState } from "../../states/authState";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from "wagmi";
import Web3 from "web3";
import { getOwner, getStageDiscontPrice } from "../../api/smart-contract";
import { TOKEN_CONTRACT_ADDRESS } from "../../consts";
import "./AdminLogin.scss";

const AdminLogin = () => {
  // const { open } = useWeb3Modal();
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const apiVersion = useRecoilValue(apiVersionState);
  const [authData, setAuthData] = useRecoilState(authState);
  const navigate = useNavigate();
  const [ownerAddress, setIsOwnerAddress] = useState("");

  const { address, isConnected } = useAccount();

  return (
    <div className="admin-login">
      <div className="admin-login__container container">
        <h1 className="admin-login__title">Admin panel</h1>

        <p className="admin-login__address">Your address: {address}</p>

        {/* <p className="admin-login__info">You are not admin</p> */}

        {isUserAdminState ? (
          <Link className="admin-login__link" to="/dashboard">Go to dashboard</Link>
          ) : (
          <p className="admin-login__subtitle">Please, connect with the owner wallet address</p>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
