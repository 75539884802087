import Web3 from 'web3';
import { RegisteredSubscription } from 'web3/lib/commonjs/eth.exports';
import { ABI, TOKEN_CONTRACT_ADDRESS, VESTING_ABI, VESTING_CONTRACT_ADDRESS } from '../consts';
import { Address } from 'viem';

type TStartStage = {
  address: string;
  web3: Web3<RegisteredSubscription>;
  amount: number;
  discont: number;
  goal: number;
  lockTime: number;
  ccTokenAddress: Address;
  realTokenAddress: Address;
  id: number;
};
export const startStage = async ({
  address,
  web3,
  id,
  lockTime,
  goal,
  discont,
  amount,
  realTokenAddress,
  ccTokenAddress,
}: TStartStage) => {
  const accounts = await web3.eth.getAccounts();
  const account = accounts[0];
  const chainId = await web3.eth.getChainId();
  const contract = new web3.eth.Contract(ABI, address);
  const contractData = contract.methods
    .startStage(
      id,
      ccTokenAddress,
      realTokenAddress,
      web3.utils.toNumber(web3.utils.toWei(amount, 'ether')),
      web3.utils.toNumber(web3.utils.toWei(discont, 'ether')),
      lockTime,
      web3.utils.toNumber(web3.utils.toWei(goal, 'ether')),
    )
    .encodeABI();
  return web3.eth.sendTransaction({
    from: account,
    chainId: chainId,
    to: address,
    value: '0x0',
    data: contractData,
  });
};

type TFinishStage = {
  address: string;
  web3: Web3<RegisteredSubscription>;
  number: number;
};

export const finishStage = async({web3, address, number}: TFinishStage) => {
  const accounts = await web3.eth.getAccounts();
  const account = accounts[0];
  const chainId = await web3.eth.getChainId();
  const contract = new web3.eth.Contract(ABI, address);
  const contractData = contract.methods.finishStage(number).encodeABI();

  return web3.eth.sendTransaction({
    from: account,
    chainId: chainId,
    to: address,
    value: '0x0',
    data: contractData,
  }).then((res) => {
    
  }).catch((error) => {
    console.log('error');
    
  });
}

type TBuyOnTokenSale = {
  address: string;
  web3: Web3<RegisteredSubscription>;
  tokenId: number;
  value: number;
  invoce: number;
};

export const buyOnTokenSale = async ({
  web3,
  address,
  tokenId,
  value,
  invoce,
}: TBuyOnTokenSale) => {
  const accounts = await web3.eth.getAccounts();
  const account = accounts[0];
  const chainId = await web3.eth.getChainId();
  const gasPrice = await web3.eth.getGasPrice();
  const contract = new web3.eth.Contract(ABI, address);
  const gasAmount = await contract.methods
    .buyOnTokenSale(tokenId, invoce)
    .estimateGas({
      from: account,
      value: web3.utils.toBigInt(web3.utils.toWei(value, 'ether')).toString(),
    });
  const contractData = contract.methods
    .buyOnTokenSale(tokenId, invoce)
    .encodeABI();
  return web3.eth.sendTransaction({
    from: account,
    chainId: chainId,
    to: address,
    value: web3.utils.toBigInt(web3.utils.toWei(value, 'ether')),
    data: contractData,
    // gas: web3.utils.toHex(web3.utils.toWei(gasPrice * gasAmount, 'wei')),
  });
};

type TGetStageInfo = {
  web3: Web3<RegisteredSubscription>;
  id: number;
  address: string;
};

export type TStageData = {
  amountStage: string,
  goal: string,
  discontPrice: string,
  totalAmount: string,
  refund: boolean,
  start: boolean,
  lockTime: string
}

export const getStageFullInfo = async ({
  web3,
  id,
  address,
}: TGetStageInfo) => {
  const contract = new web3.eth.Contract(ABI, address);
  const data:TStageData = await contract.methods.stages(id).call();
  return data;
};

export const getOwner = async ({
  web3,
  address
}: any) => {
  const contract = new web3.eth.Contract(ABI, address);
  const data: any = await contract.methods.owner().call();
  return data;
};

export const getStageDiscontPrice = async ({
  web3,
  id,
  address,
}: TGetStageInfo) => {
  const contract = new web3.eth.Contract(ABI, address);
  const { discontPrice } = await contract.methods.stages(id).call();
  return web3.utils.fromWei(discontPrice, 'ether');
};

export const getStageStart = async ({
  web3,
  id,
  address,
}: TGetStageInfo) => {
  const contract = new web3.eth.Contract(ABI, address);
  const { start } = await contract.methods.stages(id).call();
  return start;
};

type TGetStageFinishTime = {
  web3: Web3;
  userAddress: Address;
  invoceId: number;
}

export const getStageFinishTime = async ({
  userAddress,
  invoceId,
  web3,
}: TGetStageFinishTime) => {
  const contract = new web3.eth.Contract(VESTING_ABI, VESTING_CONTRACT_ADDRESS);
  const { releaseDate } = await contract.methods.vestingSchedules(invoceId).call();
  return new Date(Number(releaseDate) * 1e3);
};

export const getIsWithdrawed = async ({
  userAddress,
  invoceId,
  web3,
}: TGetStageFinishTime) => {
  const contract = new web3.eth.Contract(VESTING_ABI, VESTING_CONTRACT_ADDRESS);
  const { withdrowed } = await contract.methods.vestingSchedules(invoceId).call();
  return withdrowed;
};

export const getStageAmount = async ({
  invoceId,
  web3,
}: TGetStageFinishTime) => {
  const contract = new web3.eth.Contract(VESTING_ABI, VESTING_CONTRACT_ADDRESS);
  const { amount } = await contract.methods.vestingSchedules(invoceId).call();
  return Number(amount);
};

type TUserStage = {
  web3: Web3;
  userAddress: Address;
  invoceId: number;
}

export const claimStage = async ({ invoceId, web3, userAddress }: TUserStage) => {
  const chainId = await web3.eth.getChainId();
  const contract = new web3.eth.Contract(VESTING_ABI, VESTING_CONTRACT_ADDRESS);
  const contractData = contract.methods
    .withdraw(invoceId)
    .encodeABI();
  return web3.eth.sendTransaction({
    from: userAddress,
    chainId: chainId,
    to: VESTING_CONTRACT_ADDRESS,
    data: contractData,
  });
};

export const refundStage = async ({ userAddress, invoceId, web3 }: TUserStage) => {
  const chainId = await web3.eth.getChainId();
  const contract = new web3.eth.Contract(VESTING_ABI, VESTING_CONTRACT_ADDRESS);
  const contractData = contract.methods
    .refund(invoceId)
    .encodeABI();
  return web3.eth.sendTransaction({
    from: userAddress,
    chainId: chainId,
    to: VESTING_CONTRACT_ADDRESS,
    data: contractData,
  });
};

type TRefundCheck = {
  web3: Web3,
  byteId: number;
}

export const refundCheck = async ({ web3, byteId }: TRefundCheck) => {
  const contract = new web3.eth.Contract(ABI, TOKEN_CONTRACT_ADDRESS);
  const { refund } = await contract.methods.stages(byteId).call();
  return refund;
};
