import axios from 'axios';
import { apiURL } from '../constants/apiURL';

const api = axios.create({ baseURL: apiURL });

type TGetNonceOptions = {
  apiVersion: string;
  walletAddr: string;
};

type TNonceResponse = {
  // message:string;
  nonce: string;
};

async function getNonce({ apiVersion, walletAddr }: TGetNonceOptions) {
  return (await api.get(`/v${apiVersion}/security/users/${walletAddr}/nonce`))
    .data as TNonceResponse;
}

type TAuthAdminOptions = {
  apiVersion: string;
  username: string;
  password: string;
};

type TAuthResponse = {
  access_token: string;
  token_type: string;
};

async function authAdmin({
  apiVersion,
  password,
  username,
}: TAuthAdminOptions) {
  return (
    await api.post(`/v${apiVersion}/security/admins/signin`, {
      username,
      password,
    })
  ).data as TAuthResponse;
}

type TAuthUserOptions = {
  apiVersion: string;
  wallet_addr: string;
  signature: string;
  nonce: string;
};

async function authUser({
  apiVersion,
  nonce,
  wallet_addr,
  signature,
}: TAuthUserOptions) {
  return (
    await api.post(`/v${apiVersion}/security/users/signin`, {
      wallet_addr,
      nonce,
      signature,
    })
  ).data as TAuthResponse;
}

type TRegisterOptions = {
  apiVersion: string;
  wallet_addr: string;
};

async function registerUser({ apiVersion, wallet_addr }: TRegisterOptions) {
  return (
    await api.post(
      `/v${apiVersion}/security/users/register`,
      {},
      { params: { wallet_addr } },
    )
  ).data as TNonceResponse;
}

type TAuthorizeUserOptions = {
  token: string;
  token_type: string;
  apiVersion: string;
};

async function authorizeUser({
  token,
  token_type,
  apiVersion,
}: TAuthorizeUserOptions) {
  return await api.get(`/v${apiVersion}/security/users/token/check`, {
    headers: { Authorization: `${token_type} ${token}` },
  });
}

async function authorizeAdmin({
  token,
  token_type,
  apiVersion,
}: TAuthorizeUserOptions) {
  return await api.get(`/v${apiVersion}/security/admins/token/check`, {
    headers: { Authorization: `${token_type} ${token}` },
  });
}

export const authApi = {
  getNonce,
  authAdmin,
  authUser,
  registerUser,
  authorizeAdmin,
  authorizeUser,
};
