import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useRecoilState, useRecoilValue } from "recoil";
import { AboutProject } from "../../components/AboutProject/AboutProject";
import { EditButton } from "../../components/EditButton/EditButton";
import { OtherCard } from "../../components/OtherCard/OtherCard";
import { ProjectCalculating } from "../../components/ProjectCalculating/ProjectCalculating";
import { ProjectInfo } from "../../components/ProjectInfo/ProjectInfo";
import { Roadmap } from "../../components/Roadmap/Roadmap";
import { Tokenomic } from "../../components/Tokenomic/Tokenomic";
import { EditPopUpContainer } from "../../components/layouts/Modal/EditPopUpContainer/EditPopUpContainer";
import { Modal } from "../../components/layouts/Modal/Modal";
import { ProjectEditor } from "../../components/layouts/Modal/ProjectEditor/ProjectEditor";
import { isUserAdmin } from "../../states/isUserAdmin";
import { ProjectDescription } from "./ProjectDescription/ProjectDescription";
import { VideoSlider } from "./VideoSlider/VideoSlider";
import { useParams } from "react-router-dom";
import { projectState } from "../../states/projectState";
import { projectsApi } from "../../api/projectsApi";
import { apiVersionState } from "../../states/apiVersionState";
import { currentStageState } from "../../states/stageState";
import { stagesApi } from "../../api/stagesApi";
import { stagesState } from "../../states/stagesState";
import "./Project.scss";
import SmartEditor from "../../components/layouts/Modal/SmartEditor/SmartEditor";
import { authState } from "../../states/authState";
import { toast } from "react-toastify";

export const Project = () => {
  const isUserAdminState = useRecoilValue(isUserAdmin);
  const apiVersion = useRecoilValue(apiVersionState);
  const [project, setProject] = useRecoilState(projectState);
  const setCurrentStage = useRecoilState(currentStageState)[1];
  const setStages = useRecoilState(stagesState)[1];
  const { token, token_type } = useRecoilValue(authState);
  const { projectID } = useParams();
  useEffect(() => {
    async function bootstrap() {
      const proj = await projectsApi.getProjectByID({
        apiVersion,
        projectID: projectID!,
      });
      setProject(proj);
    }
    if (apiVersion) {
      bootstrap();
    }
  }, [projectID, apiVersion, setProject]);

  useEffect(() => {
    async function bootstrap() {
      const stage = (
        (await stagesApi.getProjectStages({
          apiVersion,
          projectId: projectID!,
          isCurrent: true,
        })) as any[]
      )[0];
      setCurrentStage(stage);
    }
    if (apiVersion) {
      bootstrap();
    }
  }, [apiVersion, setCurrentStage, projectID]);

  useEffect(() => {
    async function bootstrap() {
      const stages = (await stagesApi.getProjectStages({
        apiVersion,
        projectId: projectID!,
        isCurrent: false,
      })) as any[];
      setStages(stages.map((stage) => ({ ...stage, project: project })));
    }
    if (apiVersion) {
      bootstrap();
    }
  }, [apiVersion, setStages, projectID, project]);

  const editProject = async (proj: any) => {
    await projectsApi.updateProject({
      apiVersion,
      projectID: projectID!,
      data: proj,
      access_token: token,
      token_type,
    });
    const newProject = await projectsApi.getProjectByID({
      apiVersion,
      projectID: projectID!,
    });
    setProject(newProject);
    toast.success("Project updated");
  };

  //TODO edit or more
  const canEditThisProject = true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenContract, setIsModalOpenContract] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const [isVisibleTagEditor, setIsVisibleTagEditor] = useState(false);
  const [isVisibleTokenEditor, setVisibleTokenEditor] = useState(false);
  const [popupTitle, setPopupTitle] = useState("project information");
  const onBack = () => {
    setIsVisibleTagEditor(false);
    setVisibleTokenEditor(false);
    setPopupTitle("project information");
  };
  const settings = {
    dots: false,
    dotsClass: "slider-wrap__nav-wrap",
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="project">
      <div className="project__container">
        <div className="container">
          {canEditThisProject && isUserAdminState && (
            <>
              <Modal isOpen={isModalOpen} maxWidth={"374px"}>
                <EditPopUpContainer
                  title={popupTitle}
                  onClose={() => setIsModalOpen(false)}
                  clickOnBackButton={onBack}
                  isArrowButton={isVisibleTagEditor || isVisibleTokenEditor}
                >
                  <ProjectEditor
                    closeProjectEditor={() => setIsModalOpen(false)}
                    editTags={isVisibleTagEditor}
                    onEditTags={() => {
                      setIsVisibleTagEditor(true);
                      setPopupTitle("click to choose");
                    }}
                    editToken={isVisibleTokenEditor}
                    onEditToken={() => {
                      setVisibleTokenEditor(true);
                      setPopupTitle("upload new image");
                    }}
                    project={project}
                    onEditConfirm={editProject}
                    onBack={onBack}
                  />
                </EditPopUpContainer>
              </Modal>
              <Modal isOpen={isModalOpenContract} maxWidth={"374px"}>
                <EditPopUpContainer
                  title={"Smart contracts"}
                  onClose={() => setIsModalOpenContract(false)}
                  clickOnBackButton={onBack}
                  isArrowButton={isVisibleTagEditor || isVisibleTokenEditor}
                  
                >
                  <SmartEditor
                    project={project}
                    onSmartEditConfirm={editProject}
                    closeSmartEditor={() => setIsModalOpenContract(false)}
                  />
                </EditPopUpContainer>
              </Modal>
              <div className="project__buttons">
                <EditButton
                  onClick={() => setIsModalOpenContract(true)}
                  className={"project__edit-project-button"}
                  title={"Edit contracts"}
                />
                <EditButton
                  onClick={() => setIsModalOpen(true)}
                  className={"project__edit-project-button"}
                  title={"Edit project"}
                />
              </div>
            </>
          )}

          <div className="project__top">
            <ProjectInfo />
            <ProjectCalculating />
          </div>
        </div>
        <div className="project__tabs">
          <Tabs selectedTabClassName="project__tab_active">
            <div className="container">
              <TabList className="project__tab-list">
                <Tab className="project__tab">About project</Tab>
                <Tab className="project__tab">Coin</Tab>
                <Tab className="project__tab">Stages</Tab>
                <Tab className="project__tab">Video</Tab>
              </TabList>
            </div>

            <div className="project__tab-content">
              <TabPanel className="project__tab-panel">
                <ProjectDescription
                  isEditable={isUserAdminState && canEditThisProject}
                />
              </TabPanel>
              <TabPanel className="project__tab-panel container">
                <Tokenomic />
                {/*<PhotoSlider/>*/}
              </TabPanel>
              <TabPanel className="project__tab-panel">
                <div className="project__slider-wrapper">
                  <Roadmap
                    isEditable={isUserAdminState && canEditThisProject}
                  />
                </div>
              </TabPanel>
              <TabPanel className="project__tab-panel">
                <VideoSlider
                  isEditable={isUserAdminState && canEditThisProject}
                />
              </TabPanel>
            </div>
          </Tabs>
        </div>

        <div className="project__also container">
          <p className="project__also-title">Other Hot Projects</p>

          <div className="project__also-slider">
            <Slider
              className={"slider-wrap__slider"}
              ref={sliderRef}
              {...settings}
            >
              <OtherCard />
              <OtherCard />
              <OtherCard />
              <OtherCard />
              <OtherCard />
              <OtherCard />
              <OtherCard />
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
