import './StageEditItem.scss';
interface StageEditItemProps {
    onEditStageClick: () => void;
    stageIndex: number;
}
export const StageEditItem = ({ onEditStageClick, stageIndex }:StageEditItemProps) => {
  return (
    <div onClick={() => onEditStageClick()} className={'stage-edit-item'}>
     Edit stage {stageIndex}
    </div>
  );
};
