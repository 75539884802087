import React, { useEffect, useRef, useState } from "react";
import "./Profile.scss";
import { TokenStats } from "../../components/TokenStats/TokenStats";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TokensList, UserTokensData } from "../../components/TokensList/TokensList";
import { stagesApi } from "../../api/stagesApi";
import { useRecoilValue } from "recoil";
import { apiVersionState } from "../../states/apiVersionState";
import { useAccount } from "wagmi";
import { TokenRowProps } from "../../components/TokenRow/TokenRow";
import { projectsApi } from "../../api/projectsApi";
// import 'react-tabs/style/react-tabs.css';

type TStage = TokenRowProps;

export const Profile = () => {
  const apiVersion = useRecoilValue(apiVersionState);
  //const address = "0xE31CeFcbc0fEBEa7d06eF77635e3A5FD2DF62CBE";
  const { address } = useAccount();
  
  const mockTokens:UserTokensData = {
    "760748": {
      stage_num: 1,
      project_id: "3be60911-1416-40a7-af19-897f2ca43a57",
      byte_id: 240843914,
      number_of_tokens: 0,
      max_coins_per_hand: 0,
      start_price_usd: 0,
      discount: 50,
      start_date: "2023-10-22T15:20:00",
      finish_date: "2023-10-24T15:20:00",
      at_the_end_of_sale: false,
      freeze_time: 86400,
      id: "e6b34c73-5f84-48f4-91e9-e111e0f97654"
    },
  }

  const [history, setHistory] = useState<TStage[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState();
  useEffect(() => {
    if (apiVersion) {

      (async () => {
        if (!address) {
          alert("Log in your account");
          return;
        }

        console.log(address);

        const { data } = await stagesApi.getUserStages({
          address: address,
          apiVersion,
        }).catch((error) => error).then((res) => res);

        console.log(data);
        

        setUserData(data)

        const history: any = [];

        for (const key in data) {
          const item = data[key];

          const response = await projectsApi.getProjectByID({
            projectID: item.project_id,
            apiVersion,
          }).catch((error) => error).then((res) => {
              if (res && res.id === item.project_id) {
                history.push({
                  project_id: item.project_id,
                  tokens: item.number_of_tokens,
                  buyTokenPrice: item.start_price_usd,
                  nowTokenPrice: 23234234,
                  invoceId: Number(key),
                  byteId: item.byte_id,
                });
              }
          });
        }
        
        setHistory(history);
      })();
    }
  }, [address, apiVersion]);

  return (
    <div className="profile">
      <div className="profile__container container">
        {/* <TokenStats /> */}
        {/* <BalanceChart/> */}
        <div className="profile__tabs">
          <Tabs
            selectedTabClassName="profile__tab_active"
            selectedIndex={tabIndex}
            onSelect={setTabIndex}
          >
            <TabList className="profile__tab-list">
              {/* <Tab className="profile__tab">Actives</Tab> */}
              <Tab className="profile__tab">Actives</Tab>
            </TabList>

            {/* <TabPanel>
              {userData && (

                <TokensList data={mockTokens} />
              )}
              <center>
                <h2>You have no tokens</h2>
              </center>
            </TabPanel> */}
            <TabPanel>
              <TokensList data={history} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
