import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import './TokenRow.scss';
import { projectsApi } from '../../api/projectsApi';
import { useRecoilValue } from 'recoil';
import { apiVersionState } from '../../states/apiVersionState';
import { useAccount } from 'wagmi';
import { ABI, TOKEN_CONTRACT_ADDRESS } from '../../consts';
import Web3 from 'web3';
import {
  claimStage,
  getIsWithdrawed,
  getStageAmount,
  getStageDiscontPrice,
  getStageFinishTime,
  getStageStart,
  refundCheck,
  refundStage,
} from '../../api/smart-contract';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

export interface TokenRowProps {
  tokens: string;
  buyTokenPrice: number;
  nowTokenPrice: number;
  invoceId: number;
  project_id: string;
  byteId: number;
}

export const TokenRow = ({
  tokens,
  buyTokenPrice,
  nowTokenPrice,
  invoceId,
  project_id,
  byteId,
}: TokenRowProps) => {
  const [projectName, setProjectName] = useState('');
  const [projectSymbol, setProjectSymbol] = useState('');
  const [finishDate, setFinishDate] = useState(new Date());
  const [isRefund, setIsRefund] = useState(false);
  const [amount, setAmount] = useState<any>();
  const [buyPrice, setBuyPrice] = useState<any>();
  const [isWithdrawed, setIsWithdrawed] = useState<boolean>();
  const [stateTrigger, setStateTrigger] = useState<boolean | null>(null)
  const [isStageGoing, setIsStageGoing] = useState<boolean>(true)

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const apiVersion = useRecoilValue(apiVersionState);
  //const address = "0xE31CeFcbc0fEBEa7d06eF77635e3A5FD2DF62CBE";
  const { address } = useAccount();

  useEffect(() => {
    if (project_id) {
      console.log(isWithdrawed);
      console.log("refund: " + isRefund);
      

      (async () => {
        if (!address || !window.ethereum) {
          return;
        }
        const data = await projectsApi.getProjectByID({
          projectID: project_id,
          apiVersion,
        }).catch((error) => error).then((res) => res);

        setProjectName(data.name);
        setProjectSymbol(data.symbol)

        const web3 = new Web3(window.ethereum);

        const callIsWithdrawed = await getIsWithdrawed({
          invoceId,
          web3,
          userAddress: address,
        })

        setIsWithdrawed(callIsWithdrawed);

        const callIsStageStart = await getStageStart({
          address: TOKEN_CONTRACT_ADDRESS,
          id: byteId,
          web3: web3,
        })

        setIsStageGoing(callIsStageStart);

        const res = await getStageFinishTime({
          invoceId,
          web3,
          userAddress: address,
        })

        const discountPrice = await getStageDiscontPrice({
          address: TOKEN_CONTRACT_ADDRESS,
          id: byteId,
          web3: web3,
        });

        const weiAmount = await getStageAmount({ invoceId, web3, userAddress: address });
        const wei = ethers.utils.formatEther(BigInt(weiAmount))

        setBuyPrice(discountPrice)
        setAmount(wei)

        console.log(res);
        if (res) {
          setFinishDate(res);
        }
      })();
    }
  }, [project_id, stateTrigger]);

  useEffect(() => {
    const getTime = () => {
      const time = finishDate.getTime() - Date.now();
      setTime({
        days: Math.floor(time / (1000 * 60 * 60 * 24)),
        hours: Math.floor((time / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((time / 1000 / 60) % 60),
        seconds: Math.floor((time / 1000) % 60),
      });
    };
    if (time.seconds >= 0) {
      const interval = setInterval(getTime, 1000);
      return () => clearInterval(interval);
    }
  }, [finishDate, time]);

  useEffect(() => {
    const checkIsRefund = async () => {
      const web3 = new Web3(window.ethereum);
      const isRef = await refundCheck({ web3, byteId }).then((res) => setIsRefund(res)).catch((er) => console.log(er)
      );
    };

    checkIsRefund()

    // const interval = setInterval(checkIsRefund, 10000);
    // return () => clearInterval(interval);
  }, [byteId]);

  const onClaim = async () => {
    const web3 = new Web3(window.ethereum);
    console.log(invoceId);
    console.log(address);
    
    const claim = claimStage({ invoceId, userAddress: address!, web3 }).then(() => setStateTrigger(true));

    toast.promise(claim, {
      success: 'You have claimed tokens!',
      error: 'Something is wrong. Please, try again',
      pending: 'Loading...',
    });

    // setTriggerToUpdate()
  };

  const onRefund = async () => {
    const web3 = new Web3(window.ethereum);
    const refund = refundStage({ web3, invoceId, userAddress: address! }).then(() => setStateTrigger(true));

    toast.promise(refund, {
      success: 'You have refunded tokens!',
      error: 'Something is wrong. Please, try again',
      pending: 'Loading...',
    });
  };

  return (
    <>
      <div className='token-row'>
        <div className={cn('token-row__head')}>
          <p className='token-row__name'>{projectName}</p>
          <p className='token-row__token'>{parseFloat(Number(amount).toFixed(2)).toLocaleString()} {projectSymbol}</p>
          <p className='token-row__buy'>{buyPrice * amount} ETH</p>
          <div className='token-row__now'>
            +$104391
            <div className='token-row__percent'>
              <span className='token-row__percent-total'>+$1000</span>
              <span className='token-row__percent-number'>13%</span>
            </div>
          </div>
          <div className='token-row__time'>

            {!isRefund ? (
              <>
                {finishDate.getTime() < new Date().getTime() ?
                  isStageGoing ?
                    (
                      <div className='token-row__button token-row__button_disabled'>
                        CLAIM
                      </div>
                    )
                    :
                    !isWithdrawed ? (
                      <div className='token-row__button' onClick={onClaim}>
                        CLAIM
                      </div>
                    ) : (
                      <div className='token-row__button token-row__button_disabled'>
                        CLAIMED
                      </div>
                    ) 
                    
                    : (
                    <div className='token-row__time-left'>
                      {(time.days < 10 ? `0${time.days}` : time.days) +
                        ':' +
                        (time.days < 10 ? `0${time.days}` : time.days) +
                        ':' +
                        (time.minutes < 10 ? `0${time.minutes}` : time.minutes) +
                        ':' +
                        (time.seconds < 10 ? `0${time.seconds}` : time.seconds)}
                    </div>
                  )}
              </>
            ) :
              isWithdrawed ?

                isRefund ? <div className='token-row__button token-row__button_disabled'>
                  REFUNDED
                </div> :

                  (
                    <div className='token-row__button token-row__button_disabled'>
                      CLAIMED
                    </div>
                  ) :
                (
                  <div className='token-row__button token-row__button_refund' onClick={onRefund}>
                    REFUND
                  </div>
                )}
          </div>
          {/* <div className='token-row__arrow-button' onClick={toggleDropdown}> */}
          {/*   <Arrow */}
          {/*     className={cn('token-row__arrow', { */}
          {/*       'token-row__arrow_opened': isOpened, */}
          {/*     })} */}
          {/*   /> */}
          {/* </div> */}
        </div>
        {/* <div */}
        {/*   className={cn('token-row__content', { */}
        {/*     'token-row__content_opened': isOpened, */}
        {/*   })} */}
        {/* > */}
        {/*   <div></div> */}
        {/*   <div></div> */}
        {/*   <p className='token-row__buy'>0.5 USDT</p> */}
        {/*   <div className='token-row__now'> */}
        {/*     +$104391 */}
        {/*     <div className='token-row__percent'> */}
        {/*       <span className='token-row__percent-total'>+$1000</span> */}
        {/*       <span className='token-row__percent-number'>13%</span> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
      <div className='token-row_mobile'>
        <div className='token-row__head'>Mobox Arcade the Game</div>
        <div className='token-row__mobile__table'>
          <div className='token-row_mobile__table-row'>
            <div className='token-row_mobile__table-head'>purchase price</div>
            <div className='token-row_mobile__table-head'>current price</div>
          </div>
        </div>
      </div>
    </>
  );
};
