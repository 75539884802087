import { useEffect, useMemo, useState } from "react";
import { ReactComponent as ProjectIcon } from "../../icons/icon-project.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../icons/search-icon-dashboard.svg";
import { Project } from "./Project/Project";

import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { projectsApi } from "../../api/projectsApi";
import { versionApi } from "../../api/versionApi";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { EditPopUpContainer } from "../../components/layouts/Modal/EditPopUpContainer/EditPopUpContainer";
import { Modal } from "../../components/layouts/Modal/Modal";
import { apiVersionState } from "../../states/apiVersionState";
import { projectsState } from "../../states/projectsState";
import "./Dashboard.scss";
import { authState } from "../../states/authState";

const Dashboard = () => {
  const [projectSearch, setProjectSearch] = useState("");
  const [projects, setProjects] = useRecoilState(projectsState);
  const [apiVersion, setApiVersion] = useRecoilState(apiVersionState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [project, setProject] = useState<any>(null);
  const navigate = useNavigate();
  const { token, token_type } = useRecoilValue(authState);
  useEffect(() => {
    async function bootstrap() {
      const apiV = await versionApi.getVersion();
      setApiVersion(apiV);
    }
    bootstrap();
  }, [setApiVersion]);

  useEffect(() => {
    async function bootstrap() {
      const projs = (await projectsApi.getProjects({ apiVersion })) as any[];
      setProjects(projs);
    }
    if (apiVersion) {
      bootstrap();
    }
  }, [apiVersion, setProjects]);

  const currentProjects = useMemo(() => {
    const res = projects.filter((proj) =>
      (proj.name as string).toLowerCase().includes(projectSearch.toLowerCase())
    );
    return res;
  }, [projectSearch, projects]);

  const deleteProject = async () => {
    await projectsApi.deleteProject({
      apiVersion,
      projectID: project.id,
      access_token: token,
      token_type,
    });
    setProject(null);
    const newProjects = await projectsApi.getProjects({ apiVersion });
    setProjects(newProjects);
    setIsModalVisible(false);
    toast("Project successfully deleted", { type: "success" });
  };

  const addProject = async () => {
    const { project } = await projectsApi.addProject({
      apiVersion,
      data: {
        name: "",
        coin: "",
        symbol: "",
        whitepaper_link: "",
        tokenomics_link: "",
        website_link: "",
        community_links: [],
        video_links: [],
        tags: [],
        descriptions: [],
        coin_image_path: "",
        project_image_path: "",
        card_image_path: "",
        created_at: new Date().toISOString(),
      },
      access_token: token,
      token_type,
    });
    navigate(`/project/${project}`);
  };

  return (
    <div className="container dashboard-page">
      <Modal isOpen={isModalVisible} maxWidth="340px">
        <EditPopUpContainer
          onClose={() => setIsModalVisible(false)}
          title="DELETE CONFIRM"
        >
          <div>
            <p style={{ marginTop: "15px" }}>
              Project will be deleted (you can not undo this).
            </p>
            <ConfirmButton onClick={deleteProject}>Yes</ConfirmButton>
            <ConfirmButton onClick={() => setIsModalVisible(false)}>
              No
            </ConfirmButton>
          </div>
        </EditPopUpContainer>
      </Modal>
      <div className="dashboard-page__title-bar title-bar">
        <div className="title-bar__left-part">
          <div className="title-bar__title">
            <div className="title-bar__title-icon">
              <ProjectIcon />
            </div>
            <div className="title-bar__title-text">
              Projects{" "}
              <span className="title-bar__title-points">
                ({projects.length})
              </span>
            </div>
          </div>
          <div className="title-bar__search-field">
            <SearchIcon className="title-bar__search-field-icon" />
            <input
              type="text"
              className="title-bar__search-field-input"
              placeholder="Search"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
            />
          </div>
        </div>
        <button className="title-bar__button" onClick={addProject}>
          <PlusIcon /> Create project
        </button>
      </div>
      <div className="dashboard-page__project-positions">
        {currentProjects.map((proj) => (
          <Project
            project={proj}
            key={proj.id}
            onProjectEdit={() => {
              navigate(`/project/${proj.id}`);
            }}
            onProjectDelete={(project) => {
              setProject(project);
              setIsModalVisible(true);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
