import './ProjectCalculating.scss';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import React, { useEffect, useState } from 'react';
import { CounterBlock } from '../CounterBlock/CounterBlock';
import { useRecoilValue } from 'recoil';
import { currentStageState } from '../../states/stageState';
import Web3 from 'web3';
import { TStageData, getStageFullInfo } from '../../api/smart-contract';
import { TOKEN_CONTRACT_ADDRESS } from '../../consts';

export const ProjectCalculating = () => {
  const stage = useRecoilValue(currentStageState);
  const [timeDiff, setTimeDiff] = useState(0);
  const [currentStageWeb3, setCurrentStageWeb3] = useState<TStageData>();

  useEffect(() => {
    if (stage) {
      const interval = setInterval(() => {
        const curDate = new Date();
        if (curDate > stage.start_date) {
          setTimeDiff(stage.finish_date.getTime() - curDate.getTime());
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timeDiff, stage]);;

  useEffect(() => {
    if (stage) {
      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        const response = await getStageFullInfo({
          address: TOKEN_CONTRACT_ADDRESS,
          id: stage.byte_id,
          web3: web3,
        });
  
        setCurrentStageWeb3({
          amountStage: web3.utils.fromWei(response.amountStage, 'ether'),
          goal: web3.utils.fromWei(response.goal, 'ether'),
          discontPrice: web3.utils.fromWei(response.discontPrice, 'ether'),
          totalAmount: web3.utils.fromWei(response.totalAmount, 'ether'),
          refund: response.refund,
          start: response.start,
          lockTime: response.lockTime
        });
      })();
    }

  }, [stage])


  if (!stage) return <div className='calculating' style={{ display: 'none' }}></div>;

  return (
    <div className={'calculating'} >
      <div className='calculating__head'>
        {/* <div>Bitcoin Price <span>(BTC)</span></div> */}
        STAGE {stage.stage_num}
      </div>

      <div className='calculating__prices-wrap'>
        <div className='calculating__prices'>
          <div className='calculating__prices-row'>
            <span className={'calculating__current-price'}>${currentStageWeb3?.discontPrice}
            </span>
            {stage.discount > 0 && <span className={'calculating__percent'}>
              -{stage.discount}%
            </span>}
          </div>
          {stage.discount > 0 && <span className={'calculating__old-price'}>
            ${stage.start_price_usd}
          </span>}
        </div>

      </div>

      <div className='calculating__data-wrap'>
        <div className='calculating-dollars'>
          <div className='calculating__second-title'>Collecting coins</div>
          {stage && <ProgressBar
            max={currentStageWeb3 ? Number(currentStageWeb3.goal) / Number(currentStageWeb3.discontPrice) : 0} 
            current={currentStageWeb3 ? Number(currentStageWeb3.totalAmount) / Number(currentStageWeb3.discontPrice) : 0} 
            high={true} />}
          <div className='calculating__second-title'>Collecting dollars</div>
          {stage && 
          <ProgressBar 
            dollar 
            max={currentStageWeb3 ? Number(currentStageWeb3.goal) : 0} 
            current={currentStageWeb3 ? Number(currentStageWeb3.totalAmount) : 0} 
            high={true}
          />}
        </div>
        <div className='calculating-coins'>
          <div className={'calculating-dollars__freeze'}>WITH 12 MONTH FREEZE</div>
          <div>
            <CounterBlock className={'calculating__counter'} timeDiff={timeDiff} />
          </div>
        </div>
      </div>

    </div>
  );
};
