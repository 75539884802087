import axios from 'axios';
import { apiURL } from '../constants/apiURL';


const api = axios.create({ baseURL:apiURL });

type TUploadImageOptions = {
  data:FormData;
  apiVersion:string;
  projectID:string;
  imageType:'profile' | 'card' | 'coin';
  token_type: string,
  access_token:string

}

type TUploadImageResponse = {
  image:string;
}

async function uploadImage({ apiVersion,data,imageType,projectID, token_type, access_token  }:TUploadImageOptions) {
  return (await api.patch(`/v${apiVersion}/projects/${projectID}/images/upload`, 
    data,  { params:{ image_type:imageType }, headers: { Authorization: `${token_type} ${access_token}` } })).data as TUploadImageResponse;
}

export const imagesApi = { uploadImage };
