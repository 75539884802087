import cn from 'classnames';
import { ReactComponent as ArrowSvg } from '../../icons/slider-arrow.svg';
import './NavArrows.scss';
interface NavArrowsProps {
    onLeftArrowClick: () => void;
    onRightArrowClick: () => void;
    className?: string;
}
export const NavArrows = ({ onLeftArrowClick, onRightArrowClick, className }: NavArrowsProps) => {
  return (
    <div className={cn('nav-arrows', className)}>
      <div
        className='nav-arrows__button'
        onClick={() => onLeftArrowClick()}
      >
        <ArrowSvg/>
      </div>
      <div
        className='nav-arrows__button'
        onClick={() => onRightArrowClick()}
      >
        <ArrowSvg style={{ transform: 'scale(-1, 1)' }}/>
      </div>
    </div>
  );
};
