import './Gift.scss';

export const Gift = () => {
  return (
    <section className={'gift'}>
      <div className='container gift__container'>

        <div className='gift__images'>
          <img src='/images/boxes.png' alt='Boxes'/>
        </div>

        <div className='gift__content'>
          <h2 className={'gift__title'}>Dex Gift bonus</h2>
          <p className={'gift__paragraph'}>
            Get 50% off on token purchases, with a 1-year lock-in period. Invest smart and save big. Limited
            time offer. Buy now to secure your financial future!
          </p>
          <div className={'gift__button'}>
            Get bonus
          </div>
        </div>

        <img src={'/images/box-rotate.png'} alt={'Box'} className='gift__box'/>
      </div>
    </section>
  );
};
