import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as FillBg } from '../../../icons/filled-part.svg';
import { ReactComponent as CoinIcon } from '../../../icons/coins.svg';
import { ReactComponent as DollarIcon } from '../../../icons/dollars-icon.svg';
import { ReactComponent as CoinsHand } from '../../../icons/coins-hand.svg';
import { ReactComponent as CoinsSwap } from '../../../icons/coins-swap.svg';
import { ReactComponent as Edit } from '../../../icons/edit-icon.svg';
import { ReactComponent as Delete } from '../../../icons/trash-icon.svg';
import { ReactComponent as IconProject } from '../../../icons/proj-icon.svg';
import { stagesApi } from '../../../api/stagesApi';
import { apiVersionState } from '../../../states/apiVersionState';
import { useRecoilValue } from 'recoil';
import './Project.scss';
import { TStageData, getStageFullInfo } from '../../../api/smart-contract';
import Web3 from 'web3';
import { TOKEN_CONTRACT_ADDRESS } from '../../../consts';

export type TStageProps = {
  project: any;
  onProjectSwap?: (project: any) => void;
  onProjectDelete?: (project: any) => void;
  onProjectEdit?: (project: any) => void;
  onProjectFund?: (project: any) => void;
}

const numberFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const Project = ({ project,
  onProjectSwap, onProjectDelete, onProjectEdit, onProjectFund }: TStageProps) => {
  const [projStage] = useState({
    finish_at: new Date(),
    money: 0,
    moneyEarned: 0,
    tokens: 0,
    tokensSold: 0,
    discount: 0,
    // stage_num:projectStage.stage_num,
  });

  const [currentStageWeb3, setCurrentStageWeb3] = useState<TStageData>();


  const [currentStage, setCurrentStage] = useState<any>();

  const apiVersion = useRecoilValue(apiVersionState);
  useEffect(() => {
    async function bootstrap() {
      const stage = (await stagesApi
        .getProjectStages({ apiVersion, isCurrent: true, projectId: project.id }))[0];
      if (!stage) return;
      setCurrentStage(stage);
    }
    bootstrap();
  }, [project, apiVersion]);

  const startFinishDiff = useMemo(() => {
    if (!currentStage) return 0;
    const res = new Date(currentStage.finish_date).getTime() - new Date(currentStage.start_date).getTime();
    return res;
  }, [currentStage]);

  const [timeDiff, setTimeDiff] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date() > new Date(currentStage.start_date)) {
        setTimeDiff(new Date(currentStage.finish_date).getTime() - new Date().getTime());
      }
    }, 1000);
    if (!currentStage || timeDiff < 0 || timeDiff > startFinishDiff) {
      clearInterval(interval);
    }
    return () => { clearInterval(interval); };
  }, [timeDiff, currentStage, startFinishDiff]);

  const seconds = Math.max(Math.floor(timeDiff / 1000) % 60, 0);
  const minutes = Math.max(Math.floor(timeDiff / (1000 * 60)) % 60, 0);
  const hours = Math.max(Math.floor(timeDiff / (1000 * 60 * 60)) % 24, 0);
  const days = Math.max(Math.floor(timeDiff / (1000 * 60 * 60 * 24)), 0);

  const moneyFill = Math.floor(projStage.moneyEarned / projStage.money * 100);
  const tokensFill = Math.floor(projStage.tokensSold / projStage.tokens * 100);

  const stageType = useMemo(() => {
    if (!currentStage) return 'intermediate';
    return timeDiff < 0 && moneyFill !== 100 && tokensFill !== 100 ? 'fail' :
      moneyFill === 100 && tokensFill === 100 ? 'success' : 'intermediate';
  }, [moneyFill, timeDiff, tokensFill, currentStage]);

  useEffect(() => {
    if (currentStage) {
      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        const response = await getStageFullInfo({
          address: TOKEN_CONTRACT_ADDRESS,
          id: currentStage.byte_id,
          web3: web3,
        });

        setCurrentStageWeb3({
          amountStage: web3.utils.fromWei(response.amountStage, 'ether'),
          goal: web3.utils.fromWei(response.goal, 'ether'),
          discontPrice: web3.utils.fromWei(response.discontPrice, 'ether'),
          totalAmount: web3.utils.fromWei(response.totalAmount, 'ether'),
          refund: response.refund,
          start: response.start,
          lockTime: response.lockTime
        });
      })();
    }
  }, [currentStage]);

  return (<div className={classNames('dashboard-project-card',
    {
      'dashboard-project-card_success': stageType === 'success',
      'dashboard-project-card_fail': stageType === 'fail',
    })}>
    <div className='dashboard-project-card__title'>
      <div className='dashboard-project-card__icon'>
        {/* <img src={project.card_image_path} alt={project.name} width={44} height={44}/> */}
        <IconProject />
      </div>
      <div className='dashboard-project-card__text'>
        {currentStage && <div className='dashboard-project-card__status'>
          {stageType === 'intermediate' && `stage ${currentStage.stage_num}`}
          {stageType !== 'intermediate' && stageType}
        </div>}
        <div className='dashboard-project-card__name'>
          {project.name}
        </div>
      </div>
    </div>
    {currentStage ? <>
      <div className='dashboard-project-card__price'>
        <div className='dashboard-project-card__price-label'>
          Bitcoin Purchase Price <span style={{ color: '#808A9D' }}>(BTC)</span>
        </div>
        <div className='dashboard-project-card__price-discounted'>
          {currentStageWeb3 && currentStageWeb3?.discontPrice}
        </div>
        <div className='dashboard-project-card__price-value'>
          {currentStage.discount !== 0 && numberFormatter.format(currentStage.start_price_usd)}
        </div>
      </div>
      <div className='dashboard-project-card__stage-timer'>
        <div className='dashboard-project-card__timer-label'>next stage</div>
        <div className='dashboard-project-card__timer-values'>
          <div className='dashboard-project-card__timer-num'>{days.toString().padStart(2, '0')}</div>
          <div className='dashboard-project-card__timer-delimiter'>:</div>
          <div className='dashboard-project-card__timer-num'>{hours.toString().padStart(2, '0')}</div>
          <div className='dashboard-project-card__timer-delimiter'>:</div>
          <div className='dashboard-project-card__timer-num'>{minutes.toString().padStart(2, '0')}</div>
          <div className='dashboard-project-card__timer-delimiter'>:</div>
          <div className='dashboard-project-card__timer-num'>{seconds.toString().padStart(2, '0')}</div>
        </div>
      </div>
      <div className='dashboard-project-card__stats'>
        <div className='dashboard-project-card__stat'>
          <div className='dashboard-project-card__stat-icon'>
            <CoinIcon />
          </div>
          <div className='dashboard-project-card__stat-progress'>
            <div className='dashboard-project-card__stat-filled' style={{ width: `${tokensFill}%` }}>
              <FillBg />
            </div>
            <div className='dashboard-project-card__stat-value'>
              {/* {projStage.tokensSold} / {projStage.tokens} */}
              {currentStageWeb3 ? Number(currentStageWeb3.amountStage) : 0}/{currentStageWeb3 ? Number(currentStageWeb3.goal) : 0}
            </div>
          </div>
        </div>
        <div className='dashboard-project-card__stat'>
          <div className='dashboard-project-card__stat-icon'>
            <DollarIcon />
          </div>
          <div className='dashboard-project-card__stat-progress'>
            <div className='dashboard-project-card__stat-filled' style={{ width: `${moneyFill}%` }}>
              <FillBg />
            </div>
            <div className='dashboard-project-card__stat-value'>
              {/* {projStage.moneyEarned} / {projStage.money} */}
              {currentStageWeb3 ? Number(currentStageWeb3.goal) : 0}/
            {currentStageWeb3 ? Number(currentStageWeb3.totalAmount) : 0} 
            </div>
          </div>
        </div>
      </div>
    </> : 'no current stage'}
    <div className='dashboard-project-card__actions'>
      <button className={classNames('dashboard-project-card__action-button',
        {
          'dashboard-project-card__action-button_success': stageType !== 'fail',
        })}
      onClick={() => {
        if (stageType === 'success' && onProjectFund) {
          onProjectFund(project);
        }
        if (stageType === 'intermediate' && onProjectEdit) {
          onProjectEdit(project);
        }
      }}>
        <div className='dashboard-project-card__action-icon '>
          {stageType === 'success' && <CoinsHand />}
          {stageType === 'intermediate' && <Edit />}
        </div>
      </button>
      <button className={classNames('dashboard-project-card__action-button',
        {
          'dashboard-project-card__action-button_fail': stageType !== 'success',
        })}
      onClick={() => {
        if (stageType === 'fail' && onProjectSwap) {
          onProjectSwap(project);
        }

        if (stageType === 'intermediate' && onProjectDelete) {
          onProjectDelete(project);
        }
      }}>
        <div className='dashboard-project-card__action-icon'>
          {stageType === 'fail' && <CoinsSwap />}
          {stageType === 'intermediate' && <Delete />}
        </div>
      </button>
    </div>
  </div>);
};
