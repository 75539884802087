import { ChangeEvent } from 'react';

export function inputHandler<T>(event: ChangeEvent<HTMLInputElement>, inputs: T, index?: number) {
  const { name, value } = event.target;

  if (Array.isArray(inputs)) {
    return inputs.map((elem, i) => i === index ? value : elem);
  } else {
    return { ...inputs, [name]: value };
  }
}

export function removeInput(index: number, inputsArray: string[]) {
  const copyArr = [...inputsArray];
  copyArr.splice(index, 1);
  return copyArr;
}
