import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as UserSvg } from '../../../icons/user.svg';
import { ReactComponent as UserWhiteSvg } from '../../../icons/user-white.svg';
import { ReactComponent as LogoutSvg } from '../../../icons/logout.svg';
import { Link, useLocation } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import './UserInfo.scss';
import { isUserAdmin } from '../../../states/isUserAdmin';
import { useRecoilState } from 'recoil';
import { authState } from '../../../states/authState';
import { isAuthUser } from '../../../states/isAuthUser';

export const UserInfo = () => {
  const [isUser, setIsUser] = useRecoilState(isAuthUser);
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const [authData, setAuthData] = useRecoilState(authState);
  const { pathname } = useLocation();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);

  const closeDropdownMenu = () => setIsVisibleDropdown(false);

  useEffect(() => {
    window.addEventListener('click', closeDropdownMenu);
  }, []);

  useEffect(() => {
    closeDropdownMenu();
  }, [pathname]);

  const onUserClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsVisibleDropdown(!isVisibleDropdown);
  };

  return (
    <div className={'user-info'}>
      <div className='user-info__address'>
        {isUserAdminState
          ? 'admin'
          : address &&
          `${address.slice(0, 4)}...${address.slice(
            address.length - 2,
            address.length,
          )}`}
      </div>

      <div onClick={onUserClick} className={'user-info__svg-wrap'}>
        <UserSvg />
      </div>

      <div
        className={cn('user-info__dropdown user-dropdown', {
          'user-info__dropdown_visible': isVisibleDropdown,
        })}
      >
        <Link to={'/profile'} className='user-dropdown__item'>
          <UserWhiteSvg /> My profile
        </Link>
        <div
          onClick={() => {
            disconnect();
            setIsUserAdmin(false);
            setAuthData({ token: '', token_type: '' });
            setIsUser(false);
            console.log('false');
          }}
          className='user-dropdown__item'
        >
          <LogoutSvg /> Log out
        </div>
      </div>
    </div>
  );
};
