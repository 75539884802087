import './Burger.scss';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { UserInfo } from '../../UserInfo/UserInfo';
import { RegisterButton } from '../../RegisterButton/RegisterButton';
import { Socials } from '../../../Socials/Socials';
import { stopScrollClass } from '../../../../constants/basic';
import { useLocation } from 'react-router-dom';
import { BurgerButton } from '../../../BurgerButton/BurgerButton';

interface BurgerProps {
    menuItems: JSX.Element[];
    showConnectors: () => void;
    isConnected: boolean;
    isConnectorsVisible: boolean;
}

export const Burger = ({ menuItems, showConnectors, isConnected, isConnectorsVisible }: BurgerProps) => {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { hash } = useLocation();

  useEffect(() => {
    setIsOpenMenu(false);
  },[hash]);

  useEffect(() => {
    if (isOpenMenu || isConnectorsVisible) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.classList.add(stopScrollClass);
    } else {
      document.body.style.paddingRight = '0';
      document.body.classList.remove(stopScrollClass);
    }
  },[isOpenMenu, isConnectorsVisible]);

  return (
    <div className={'burger'}>

      <BurgerButton openOrClose={() => setIsOpenMenu(!isOpenMenu)} isOpen={isOpenMenu}/>

      <div className={cn('burger__menu', { 'burger__menu_visible': isOpenMenu })}>
        <div className='burger__menu_inner'>

          <nav>
            <ul className={'header__menu-list_mobile'}>
              {menuItems}
            </ul>
          </nav>

          <div className='header__info_mobile'>
            {isConnected ? <UserInfo/> : <RegisterButton showConnectors={() => showConnectors()}/>}
          </div>

          <Socials className={'burger__socials'}/>

          <div className='burger__rights'>CD 2023 © All Rights reserved</div>

        </div>
      </div>

    </div>
  );
};
