import './VideoSlider.scss';
import Slider from 'react-slick';
import React, { useState } from 'react';
import { Modal } from '../../../components/layouts/Modal/Modal';
import { EditPopUpContainer } from '../../../components/layouts/Modal/EditPopUpContainer/EditPopUpContainer';
import { StagesEditor } from '../../../components/layouts/Modal/StagesEditor/StagesEditor';
import { EditButton } from '../../../components/EditButton/EditButton';
import { VideoEditor } from '../../../components/layouts/Modal/VideoEditor/VideoEditor';
import ReactPlayer from 'react-player';
import { useRecoilValue } from 'recoil';
import { projectState } from '../../../states/projectState';
interface VideoSliderProps {
  isEditable?: boolean
}
const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const VideoSlider = ({ isEditable }: VideoSliderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const project = useRecoilValue(projectState);
  console.log(project.video_links);

  return (
    <div className='photo-slider container'>

      {isEditable && (
        <>
          <Modal isOpen={isModalOpen} maxWidth={'384px'}>
            <EditPopUpContainer title={'video'} onClose={() => setIsModalOpen(false)}>
              <VideoEditor closeVideoEditor={() => setIsModalOpen(false)}/>
            </EditPopUpContainer>
          </Modal>
          <div className='roadmap__edit-button-wrapper'>
            <EditButton
              onClick={() => setIsModalOpen(true)}
              className={'roadmap__edit-button'}
              title={'Edit video'}
            />
          </div>
        </>
      )}
      {project.video_links.length !== 0 ?
    <Slider {...settings}>
        {(project.video_links as string[]).map((link, index)=><ReactPlayer url={link} key={index}/>)}
      </Slider>
    : <h2 style={{color: "#FFF"}}>No videos</h2>  
    }
    </div>
  );
};
