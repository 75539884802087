import './Socials.scss';
import { socialList } from '../../constants/socialList';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface SocialsProps {
  className?: string;
}

export const Socials = ({ className }: SocialsProps) => {

  const socialItems = socialList.map((item, i) => {
    return <Link key={i} to={item.link}>{item.icon}</Link>;
  });

  return (
    <div className={cn('socials', className)}>
      {socialItems}
    </div>
  );
};
