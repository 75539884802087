import { useEffect, useState } from "react";
import { IStage } from "../../../../data/fakeStages";
import { ReactComponent as PlusSvg } from "../../../../icons/plus.svg";
import { EditInterface } from "./EditInterface/EditInterface";
import { StageEditItem } from "./StageEditItem/StageEditItem";
import { stagesApi } from "../../../../api/stagesApi";
import "./StagesEditor.scss";
import { useRecoilValue } from "recoil";
import { apiVersionState } from "../../../../states/apiVersionState";
import { currentStageState } from "../../../../states/stageState";

interface StagesEditorProps {
  currentEditingStage: IStage | null;
  setCurrentEditingStage?: (s: IStage | null) => void;
  cancelEditingStage: () => void;
  onAddStage: () => void;
  stages: any[];
}

export const StagesEditor = ({
  currentEditingStage,
  setCurrentEditingStage,
  cancelEditingStage,
  onAddStage,
  stages,
}: StagesEditorProps) => {
  const apiVersion = useRecoilValue(apiVersionState);
  const [currentStage, setCurrentStage] = useState<any>(false)
  const [web3CurrentStage, setWeb3CurrentStage] = useState<any>()

  const stagesItems = stages.map((stage, i) => {
    return (
      <StageEditItem
        key={stage.id}
        stageIndex={stage.stage_num}
        onEditStageClick={() => setCurrentEditingStage?.(stage)}
      />
    );
  });
  
  useEffect(() => {
    console.log(currentStage);
    console.log(new Date().toUTCString());
    console.log(new Date().toISOString());
    
    console.log(new Date().getTime())
    
    
    
  }, [currentStage])
  

  useEffect(() => {
    async function bootstrap() {
        if (stages[0]?.project_id) {
        const currentStage = (await stagesApi.getProjectStages({
          apiVersion,
          projectId: stages[0].project_id,
          isCurrent: true,
        })) as any[];

        console.log(currentStage);
        
        setCurrentStage(currentStage.length === 0 ? false : true)
      }
    }
    if (apiVersion) {
      bootstrap();
    }
  }, [stages])

  return (
    <div className={"stages-editor"}>
      {currentEditingStage ? (
        <EditInterface
          stage={currentEditingStage}
          backToStages={() => cancelEditingStage()}
        />
      ) : (
        <div className="stages-editor__list">
          {stagesItems}

          {!currentStage && (
            <div onClick={onAddStage} className={"stage-edit-item"}>
              Add new stage <PlusSvg />
            </div>
          )} 
        </div>
      )}
    </div>
  );
};
