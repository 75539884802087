import { atom } from 'recoil';

export interface BuyNowData {
  price: number,
  symbol: string,
  name: string,
  discont: number,
  stageNum: number,
  freezTime: number,
  stageId: string,
  tokenId: number;
}

export const buyNowModalState = atom<null | BuyNowData>({
  key: 'buyNowModalState',
  default: null,
});
