import React from 'react'
import { ReactComponent as LinkIcon } from '../../icons/link-icon.svg';
import { ReactComponent as DocsIcon } from '../../icons/docs-icon.svg';
import { ReactComponent as CommunityIcon } from '../../icons/community-icon.svg';
import { ReactComponent as CodeIcon } from '../../icons/code-icon.svg';
import { ReactComponent as ExploreIcon } from '../../icons/search-icon.svg';
import { ProjectButtons } from '../ProjectInfo/ProjectInfo';
import "./ProjectLink.scss"

export const ProjectLink = ({name, link, type}: ProjectButtons) => {

  const getIcon = () => {
    switch (type) {
      case "link":
        return <LinkIcon />      
      case "docs":
        return <DocsIcon />      
      case "community":
        return <CommunityIcon />      
      case "code":
        return <CodeIcon />      
      case "explore":
        return <ExploreIcon />      
      default:
        break;
    }
  }

  return (
    <a href={link} className='project-link'>
      {getIcon()}
      <span className='project-link__name'>{name}</span>
    </a>
  )
}
