import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { authApi } from '../../api/authApi';
import { ReactComponent as CrossSvg } from '../../icons/cross.svg';
import { ReactComponent as MetamaskSvg } from '../../icons/metamask.svg';
import { ReactComponent as WalletConnectSvg } from '../../icons/wallet-connect.svg';
import { apiVersionState } from '../../states/apiVersionState';
import { authState } from '../../states/authState';
import { isAuthUser } from '../../states/isAuthUser';

import './Connectors.scss';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { getOwner } from '../../api/smart-contract';
import { TOKEN_CONTRACT_ADDRESS } from '../../consts';
import { isUserAdmin } from '../../states/isUserAdmin';

interface ConnectorsProps {
  onClose: () => void;
}

const connectorIcons = [<MetamaskSvg />, <WalletConnectSvg />];

export const Connectors = ({ onClose }: ConnectorsProps) => {
  const [isUser, setIsUser] = useRecoilState(isAuthUser);
  const apiVersion = useRecoilValue(apiVersionState);
  const [authData, setAuthData] = useRecoilState(authState);
  const { address, isConnected } = useAccount();
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const { connectAsync, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const [nonce, setNonce] = useState('');
  const [account, setAccount] = useState('');
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showError, setShowError] = useState(false);
  
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, []);

  const { signMessageAsync } = useSignMessage({
    onSuccess: async (data, variables, context) => {

      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        await getOwner({
          address: TOKEN_CONTRACT_ADDRESS,
          web3: web3,
        }).then((res) => {
  
          async function authAdmin() {
            
            if (res == address) {
              
              if (apiVersion) {
                const { access_token, token_type } = await authApi.authAdmin({
                  apiVersion,
                  username: "admin",
                  password: "123",
                });
                
                console.log(access_token);
                const data = await authApi.authorizeAdmin({
                  apiVersion,
                  token: access_token,
                  token_type: token_type,
                });
  
                setIsUserAdmin(true);
                toast.success("You login as admin");
                onClose()
                
                setAuthData({ token: access_token, token_type, isAdmin: true });
              }
            } else {
              const { access_token, token_type } = await authApi.authUser({
                apiVersion,
                wallet_addr: account,
                nonce: nonce,
                signature: data,
              });
        
              setIsUser(true);
              onClose()
              setAuthData({ token: access_token, token_type });
            }
          }
  
          authAdmin()
        });
      })();

      // if (authData.isAdmin === true) {
      //   console.log('you are an admin');
      // } else {
        
      // }
      // onClose();
    },
  });

  const connectTo = async (connector: any) => {
    try {
      setShowError(false);
      const connectData = await connectAsync({ connector });
      let walletNonce;
      try {
        const { nonce } = await authApi.getNonce({
          apiVersion,
          walletAddr: connectData.account,
        });
        walletNonce = nonce;
      } catch (e) {
        const { nonce } = await authApi.registerUser({
          apiVersion,
          wallet_addr: connectData.account,
        });
        walletNonce = nonce;
      }
      setAccount(connectData.account);
      setNonce(walletNonce);
      await signMessageAsync({
        message:
          'Thank you! Now you will receive all the latest news of CoinsClub',
      });
    } catch (e: any) {
      if (e.name === 'ConnectorNotFoundError' && isMobileDevice) {
        window.location.href = 'https://metamask.app.link/dapp/dexcounter.itworkin.com/';
        return;
      }
      setShowError(true);
    }
  };

  return (
    <div className={'connectors'}>
      <CrossSvg onClick={() => onClose()} className={'connectors__cross'} />
      <div className='connectors__title'>CONNECT YOUR WALLET</div>
      <div className='connectors__list'>
        {connectors.map((connector, i) => {
          return (
            <div
              key={connector.id}
              onClick={() => connectTo(connector)}
              className={cn('connectors-item', {
                // 'connectors-item_disabled': !connector.ready,
                'connectors-item_pending':
                  isLoading && connector.id === pendingConnector?.id,
              })}
            >
              <div className='connectors-item__info'>
                {connectorIcons[i]}
                {connector.name}
              </div>
              <div className='connectors-item__target-circle' />
            </div>
          );
        })}

        {error && showError && (
          <div className={'connectors__error-message'}>{error.message}</div>
        )}
      </div>
    </div>
  );
};
