export interface IStage {
    oldPrice: string;
    currentPrice: string;
    maxCoinsPerUser?: string;
    startingPrice?:string;
    discount: string;
    startDate: string;
    finishDate: string;
    sold: string;
    soldPercent: string;
    numberOfTokens: string;
    freezeDays: string;
    freezeMetric: string;
}

export enum Metrics {
    DAYS = 'Minutes',
    MONTHS = 'Months',
    YEARS = 'Years'
}

export const fakeEmptyStage = {
  oldPrice: '',
  currentPrice: '',
  discount: '',
  startDate: '',
  finishDate: '',
  sold: '',
  soldPercent: '',
  numberOfTokens: '',
  freezeDays: '6',
  freezeMetric: Metrics.MONTHS,
};
export const fakeStages: IStage[] = [
  {
    oldPrice: '$27,939.68',
    currentPrice: '$13,969.84',
    maxCoinsPerUser: "150",
    startingPrice: "20",
    discount: '-60%',
    startDate: '10.04.2023 06:00pm',
    finishDate: '15.04.2023 06:00pm',
    sold: '20,339,562 BTC',
    soldPercent: '50%',
    numberOfTokens: '150.000',
    freezeDays: '6',
    freezeMetric: Metrics.MONTHS,
  },

];
