import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { apiURL } from '../../constants/apiURL';
import { buyNowModalState } from '../../states/BuyNowModalState';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { apiVersionState } from '../../states/apiVersionState';
import { stagesApi } from '../../api/stagesApi';
import { ReactComponent as LogotypeSvg } from '../../icons/logotype.svg';
import './ProjectCard.scss';
import { toast } from 'react-toastify';
import { isAuthUser } from '../../states/isAuthUser';
import { isUserAdmin } from '../../states/isUserAdmin';
import { TStageData, getStageFullInfo } from '../../api/smart-contract';
import Web3 from 'web3';
import { TOKEN_CONTRACT_ADDRESS } from '../../consts';


interface ProjectCardProps {
  withButton?: boolean;
  project: any;
}

export const ProjectCard = ({ withButton, project }: ProjectCardProps) => {
  const setIsOpenBuyModal = useSetRecoilState(buyNowModalState);
  const [isUser, setIsUser] = useRecoilState(isAuthUser);
  const [isUserAdminState, setIsUserAdmin] = useRecoilState(isUserAdmin);
  const [currentStageWeb3, setCurrentStageWeb3] = useState<TStageData>();
  const onBuyClick = async () => {

    const data = await stagesApi.getProjectStages({
      apiVersion: apiVersion,
      isCurrent: true,
      projectId: project.id,
    });
    const activeStage = data[data.length - 1];
    console.log(activeStage);
    
    if (!activeStage) {
      return toast.error('No project active stage');
    }
    console.log(activeStage.id);
    setIsOpenBuyModal({
      name: project.coin,
      price: currentStage.start_price_usd,
      stageNum: activeStage?.stage_num || 0,
      symbol: project.symbol ?? 'no symbol',
      discont: currentStage.discount,
      freezTime: currentStage.freeze_time,
      stageId: activeStage?.id || 0,
      tokenId: activeStage?.byte_id || 0,
    });
  };

  const apiVersion = useRecoilValue(apiVersionState);
  const location = useLocation();
  const [currentStage, setCurrentStage] = useState<any>(null);
  const days = currentStage ? currentStage.freeze_time / 1000 / 60 / 24 : 0;
  const months = days / 30;
  const years = days / 365;
  const isYears = years >= 1;
  const isMonths = months >= 1;

  useEffect(() => {
    async function bootstrap() {
      const newStage = (
        await stagesApi.getProjectStages({
          apiVersion,
          projectId: project.id,
          isCurrent: true,
        })
      ).at(-1);
      if (newStage) {
        setCurrentStage(newStage);
        console.log(newStage);

      }
    }
    bootstrap();
  }, [apiVersion, location, project]);

  useEffect(() => {
    if (currentStage) {
      (async () => {
        if (!window.ethereum) {
          return;
        }
        const web3 = new Web3(window.ethereum);
        const response = await getStageFullInfo({
          address: TOKEN_CONTRACT_ADDRESS,
          id: currentStage.byte_id,
          web3: web3,
        });

        setCurrentStageWeb3({
          amountStage: web3.utils.fromWei(response.amountStage, 'ether'),
          goal: web3.utils.fromWei(response.goal, 'ether'),
          discontPrice: web3.utils.fromWei(response.discontPrice, 'ether'),
          totalAmount: web3.utils.fromWei(response.totalAmount, 'ether'),
          refund: response.refund,
          start: response.start,
          lockTime: response.lockTime
        });
      })();
    }
  }, [currentStage]);

  return (
    <div className={'project-card__outer'}>
      <div className={'project-card'}>
        <div className='project-card__inner'>
          {currentStage && <div className={'project-card__main-tag'}>ONGOING</div>}

          <div className='project-card__cover-wrap'>
            <div className='project-card__cover-info'>
              <ul className={'project-card__cover-tags'}>
                {project.tags.slice(0, 7).map((tag: string) => (
                  <li key={tag} className={'project-card__cover-tag'}>
                    {tag}
                  </li>
                ))}
              </ul>
              {currentStage && (
                <div className='project-card__cover-percent'>
                  -{currentStage?.discount}%
                </div>
              )}
            </div>

            {project.project_image_path ? (
              <img
                className={'project-card__cover'}
                src={`${apiURL}/static/projects/${project.id}/images/${project.project_image_path}`}
                alt='Cover'
              />
            ) : (
              <div className='project-card__cover project-card__cover_empty'>
                <LogotypeSvg />
              </div>
            )}
          </div>

          <h3 className={'project-card__mobile-title'}>{project.name}</h3>

          <div className='project-card__buy-text'>Buy Bitcoin Price (BTC)</div>

          <div className='project-card__main-info'>
            <div className='project-card__prices-wrap'>
              <div className={'project-card__price'}>
                {currentStageWeb3?.discontPrice}
              </div>
              {currentStage && currentStage.discount && (
                <div className={'project-card__old-price'}>
                  ${currentStage.start_price_usd.toFixed(3)}
                </div>
              )}
            </div>
            <div className='project-card__freeze'>
              {currentStage ? (
                <>
                  {Math.round(isYears ? years : isMonths ? months : days)}&nbsp;
                  {isYears ? 'YEARS' : isMonths ? 'MONTHS' : 'DAYS'} FREEZE
                </>
              ) : (
                <>NO ACTIVE STAGE</>
              )}
              
            </div>
          </div>

          <div className='project-card__calculating'>
            <div className='project-card__bar'>
              <div className='project-card__bar_inner-line' />
            </div>
            {currentStage && (
              <div className='project-card__mobile-socials'>
                <ProgressBar
                  max={currentStage ? currentStage.number_of_tokens : 0}
                  current={0}
                  high={true}
                />
              </div>
            )}
          </div>

          <div
            className={cn('project-card__button-wrap', {
              'project-card__button-wrap_visible': withButton,
            })}
          >
            <div
              onClick={isUser || isUserAdminState ? onBuyClick : () => { }}
              className='project-card__main-button main-button'
            >
              {isUser || isUserAdminState ? currentStage ? 'BUY NOW' : "NO STAGE" : ' LOGIN FIRST'}
            </div>

            <Link
              to={`/project/${project.id}`}
              className='project-card__more-button'
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
