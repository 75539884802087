import './Chart.scss';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const options = {
  responsive: true,
  elements: {
    line: {
      borderWidth: 2,
      tension: 0.3,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
  plugins: {
    legend: {
      position: 'right' as const,
      labels: {
        font: {
          family: '\'Montserat\', sans-serif',
          weight: '600',
          size: 16,
          lineHeight: '150%',
        },
        color: '#fff',
        boxWidth: 19,
        boxHeight: 19,
        padding: 14,
        useBorderRadius: true,
        borderRadius: 8,
        usePointStyle: true,
        pointStyle: 'circle',
      },

    },
    colors: {
      forceOverride: true,
    },
  },
};


const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Testnet Rewards',
      data: labels.map(() => Math.random() * 1000000000),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      fill: true,
      label: 'Testnet Rewards',
      data: labels.map(() => Math.random() * 1000000000),
      borderColor: 'rgb(182,130,32)',
      backgroundColor: 'rgba(182,130,32,0.71)',
    },
  ],
};

export const Chart = () => {

  return (
    <div>
      <Line options={options} data={data}/>
    </div>
  );
};
