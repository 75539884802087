import React, { useState } from "react";
import "./TokensList.scss";
import { TokenRow, TokenRowProps } from "../TokenRow/TokenRow";

interface TokenListProps {
  data: TokenRowProps[];
}

export interface UserTokensStage {
  stage_num: number;
  project_id: string;
  byte_id: number;
  number_of_tokens: number;
  max_coins_per_hand: number;
  start_price_usd: number;
  discount: number;
  start_date: string;
  finish_date: string;
  at_the_end_of_sale: boolean;
  freeze_time: number;
  id: string;
}

export interface UserTokensData {
  [key: string]: UserTokensStage;
}

export const TokensList = ({ data }: any) => {
  const [isOpened, setOpened] = useState(false);

  console.log(data);
  
  return (
    <div className="tokens-list">
      <div className="tokens-list__wrappper">
        <p className="tokens-list__title">Tokens</p>

        <div className="tokens-list__table">
          <div className="tokens-list__table-header">
            <p className="tokens-list__table-label">project name</p>
            <p className="tokens-list__table-label">tokens</p>
            <p className="tokens-list__table-label">purchase price</p>
            <p className="tokens-list__table-label">current price</p>
            <p className="tokens-list__table-label">unlock time</p>
          </div>
        </div>
        <div className="tokens-list__table-content">
          {data.map((item:any) => (
            <TokenRow key={item.invoceId} {...item} />
          ))}
          {data.length === 0 && (
            <center>
              <h2>You have no tokens</h2>
            </center>
          )}
        </div>
      </div>
    </div>
  );
};
