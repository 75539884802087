import { TitleWithIcon } from '../../../components/TitleWithIcon/TitleWithIcon';
import { ReactComponent as TrendSvg } from '../../../icons/trend-up.svg';
import { ProjectCard } from '../../../components/ProjectCard/ProjectCard';
import Slider from 'react-slick';
import { settingsForSlidersOnMainPage } from '../../../constants/settingsForSlidersOnMainPage';
import { BigCard } from '../../../components/BigCard/BigCard';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { apiVersionState } from '../../../states/apiVersionState';
import { projectsApi } from '../../../api/projectsApi';
import { useLocation } from 'react-router-dom';
import './TopSales.scss';

export const TopSales = () => {
  const [projects, setProjects] = useState<any>([]);
  const apiVersion = useRecoilValue(apiVersionState);
  const location = useLocation();

  useEffect(() => {
    async function bootstrap() {
      if (apiVersion) {
        const newProjects = await projectsApi.getByTag({ apiVersion, tags: ['Top Sales'] }).catch(() => false);
        if (newProjects && newProjects.length > 0) {
          setProjects(newProjects);
        }
      }
    }
    bootstrap();
  }, [apiVersion, location]);

  if (projects.length === 0) return <></>
  
  return (
    <section id={'top-sales'} className={'top-sales'}>

      <div className='container'>
        <TitleWithIcon title={'Top sales'} icon={<TrendSvg />} />

        <div className='top-sales__list'>
          {projects.map((project: any) => <ProjectCard project={project} key={project.id} />)}
        </div>

        <div className='top-sales__slider-wrap'>
          <Slider {...settingsForSlidersOnMainPage}>
            {projects.map((project: any) => <BigCard project={project} key={project.id} />)}
          </Slider>
        </div>

      </div>
    </section>
  );
};
